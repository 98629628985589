/**
 * Method to validate and format date.
 * @param {string} dateString 
 * @param {object} dateFormat 
 * @param {string} locales
 * @returns string
 */
export function DisplayDate(dateString, dateFormat, locales = 'default'){
    let date = "";
    if(new Date(dateString).toString() !== 'Invalid Date'){
        date = new Date(dateString).toLocaleString(locales, dateFormat)
    }
    return date;
}