import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger'
import reducer from '../reducers';
import saga from '../sagas'

let middleware = [];
const sagaMiddleware = createSagaMiddleware();

   if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, sagaMiddleware, logger];
   } else {
     middleware = [...middleware, sagaMiddleware];
   }

const store = createStore(reducer, applyMiddleware(...middleware));
sagaMiddleware.run(saga);

export default store;