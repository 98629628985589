import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { UNIQUE_ID_GA } from "../../constants/AppConstants";
import EcoProButton from "../shared/button/button";
import { currentUserRolesArr, validateRoleAndPathname_CSAGENT_ADMIN } from "../../constants/AppMethod";
import "./PlanCancellationFeedback.css";

const PlanCancellationFeedback = (props) => {
  const location = useLocation();
  const {selectFeedback,confirmCancellation,content,selectedFeedback,handleOtherReason,otherReason} = props
  const translationNamespace = (validateRoleAndPathname_CSAGENT_ADMIN(currentUserRolesArr(), location.pathname)) ? 'CSAgenttranslation' :'translation';
  const { t } = useTranslation([translationNamespace]);

  return (
    <div className="plan-cancellation-feedback">
      <div className="f-20 fc-e header">
        {t("CANCELLATION_FEEDBACK.HEADER")}
      </div>
      <div className="f-12 sub-header">
        {t("CANCELLATION_FEEDBACK.SUB_HEADER")}
      </div>
      <div className="row plan-cancel-card plan-cancellation-border ">
        {content && content.map((feedback) => (
          <div key={feedback.key} id={UNIQUE_ID_GA.cancelPlanFeedback+"-"+feedback.key}
            className={`row pointer-cursor ${
              selectedFeedback.includes(feedback.key)
                ? "selected-feedback"
                : null
            }`}
            onClick={() => selectFeedback(feedback.key)}
          >
            <div className="col plan-type">
              <div className="feedback-list f-14">{feedback.content}</div>
            </div>
          </div>
        ))}
      </div>
      {selectedFeedback && selectedFeedback.includes("other")?
      <div className="text-area">
        <textarea
          value={otherReason}
          key="w3review"
          name="w3review"
          placeholder={t("CANCELLATION_FEEDBACK.PLACEHOLDER")}
          onChange={(e)=>handleOtherReason(e)}
          maxLength={200}
        ></textarea>
      </div>
      :
      null}
      <div className="cancel-continue-btn">
        <EcoProButton
          disabled={selectedFeedback.length === 0 ? true : false}
          cname="default"
          onBtnClick={() => confirmCancellation()}
          btnID={UNIQUE_ID_GA.cancelPlanFeedback}
        >
          {t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CONTINUE")}
        </EcoProButton>
      </div>
      <div className="skip_section fc-e">
        <span className = "pointer-cursor" onClick={() => confirmCancellation()}>{t("EDIT_FORM.UPDATE_ACCOUNT_BTN.SKIP_STEP")}</span>
      </div>
    </div>
  );
};

export default PlanCancellationFeedback;
