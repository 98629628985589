import React from "react";
import { useTranslation } from "react-i18next";
import CustomerRedirection from "../../components/Redirection/CustomerRedirection";
import AdminRedirection from "../../components/Redirection/AdminRedirection";
import CSAgentRedirection from "../../components/Redirection/CSAgentRedirection";
import { userRoles } from "../../constants/UserRoles";
import ErrorPage from "../../components/shared/errorPage/ErrorPage";
import { currentUserRolesArr } from "../../constants/AppMethod";

const Redirection = (props) => {
  const { t } = useTranslation(["translation"]);
  const RouterLinkStateProp = props?.location?.state?.RouterLinkStateProp;
  const currentUserRoles = currentUserRolesArr();
  const CSAGENT_ROLE = userRoles.CSAGENT;
  const ADMIN_ROLE = userRoles.ADMIN;
  const CUSTOMER_ROLE = userRoles.CUSTOMER;
  const CSAGENT_ADMIN = userRoles.CSAGENT_ADMIN;
 
  if(currentUserRoles){
    if(currentUserRoles.indexOf(ADMIN_ROLE) > -1){
      return <AdminRedirection></AdminRedirection>
    }
    if(currentUserRoles.indexOf(CSAGENT_ROLE) > -1 || currentUserRoles.indexOf(CSAGENT_ADMIN) > -1){
      return <CSAgentRedirection></CSAgentRedirection>
    }
    if(currentUserRoles.indexOf(CUSTOMER_ROLE) > -1){
      return <CustomerRedirection RouterLinkStateProp = {RouterLinkStateProp}></CustomerRedirection>
    }
    return (
      <ErrorPage errorInfo={t('ERROR_CONTENT.ERR_INFO.UNAUTHORIZED_ACCESS')}></ErrorPage>
    )
  }
  return (
    <ErrorPage></ErrorPage>
  )
};

export default Redirection;
