import i18next from "i18next";
import { ENVSettings } from "../../../constants/ENVSettings";
import { EDIT_ACCESS } from "../../../constants/ActionTypes/AdminPortal/AdminUsers";

export default function validate(values, formAction) {
    let errors = {};
    if(formAction !== EDIT_ACCESS){
        const extractDomain = values.email.split("@");
        const emailValiDomainForAdminRole = ENVSettings.ADMIN_PORTAL_ADMIN_ROLE_VALID_EMAIL_DOMAINS;
        if (!values.name) {
            errors.name = i18next.t('Admintranslation:MANAGE_ADMIN_USERS.VALIDATION_MSGS.NAME.REQUIRED');
        }
        if (!values.email) {
            errors.email =  i18next.t('Admintranslation:MANAGE_ADMIN_USERS.VALIDATION_MSGS.EMAIL.REQUIRED');
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = i18next.t('Admintranslation:MANAGE_ADMIN_USERS.VALIDATION_MSGS.EMAIL.INVALID_ADDRESS');
        }
        if (!values.country) {
            errors.country = i18next.t('Admintranslation:MANAGE_ADMIN_USERS.VALIDATION_MSGS.COUNTRY.REQUIRED');;
        }
        if (!values.admin && !values.csagent && !values.csagentAdmin) {
            errors.role = i18next.t('Admintranslation:MANAGE_ADMIN_USERS.VALIDATION_MSGS.ROLE.REQUIRED');
        } else if (values.admin || (values.admin && values.csagent)){
            if(emailValiDomainForAdminRole.indexOf(extractDomain[1]) === -1){
                errors.email = i18next.t('Admintranslation:MANAGE_ADMIN_USERS.VALIDATION_MSGS.EMAIL.INVALID_DOMAIN');
            }
        }
    }
    return errors;
};