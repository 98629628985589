import { getUserSessionData, getUserDataByKey, getLocalSessionData } from "../Authentication/handleUserSession";
import { getLangCode } from "../Internationalization/handleAppInternationalization";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";

const UpdateShippingAddressPayload = (Subscription) => {
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);;
    const currency = getLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY);
    const langCode = (getLangCode()) ? getLangCode().split("-")[0] :  "";
    const buildCustomerName = () => {
        let displayName = '';
        if(Subscription.accountDetails.personalDetails.firstName){
            displayName = (Subscription.accountDetails.personalDetails.lastName) 
            ?  `${Subscription.accountDetails.personalDetails.firstName} ${Subscription.accountDetails.personalDetails.lastName}` : Subscription.accountDetails.personalDetails.firstName
        } else {
            displayName = (getUserDataByKey('lastName', 'personal_details')) 
            ? `${getUserDataByKey('firstName', 'personal_details')} ${getUserDataByKey('lastName', 'personal_details')}`
            :  getUserDataByKey('firstName', 'personal_details');
        }
        return displayName;
    }
    const customerName = buildCustomerName();
    return {
        UserId: UID,
        Name: customerName,
        email: Subscription.accountDetails.personalDetails.emailAddress || "",
        ShippingAddress: {
            FirstName: Subscription.accountDetails.personalDetails.firstName,
            LastName: Subscription.accountDetails.personalDetails.lastName ,
            Street1: Subscription.accountDetails.shippingAddress.address,
            Street2: Subscription.accountDetails.shippingAddress.addressLine2 || "",
            City: Subscription.accountDetails.shippingAddress.city,
            State: Subscription.accountDetails.shippingAddress.province || "",
            StateName: "",
            PostalCode: Subscription.accountDetails.shippingAddress.postcode,
            AddressNumber: "",
            country: Subscription.accountDetails.shippingAddress.country,
            currency:currency,
            companyName: Subscription.accountDetails.shippingAddress.company || "",
            languageCode: langCode
        },
        Device: {
            deviceId: deviceID,
        },
    }
}

export default UpdateShippingAddressPayload;