import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  changeAccountStatus,
  ManageSubscription,
  showMobileError,
  showZipCodeError,
} from "../../../actions/MyAccount";
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";
import {
  refreshBtn,
  tryAgainBtn,
  SUCCESS,
  _API_ENDPOINT,
  SUBSCRIPTION_TYPE,
  BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH,
  ADDRESS_FIELDS_INPUT_MAX_LENGTH
} from "../../../constants/AppConstants";
import EditShipping from "../../../components/EditMyAccount/EditShipping/EditShipping";
import EditBilling from "../../../components/EditMyAccount/EditBilling/EditBilling";
import Loader from "../../../components/shared/loader/Loader";
import { routePath } from "../../../constants/Routes";
import {checkEcoproSupportRegion, findMobileError,findzipCodeError, scrollToTop, trimAddressFieldsLength, validateProvinceData} from "../../../constants/AppMethod";
import { ENVSettings } from "../../../constants/ENVSettings";
import { _CALLGETAPI } from "../../../api/apiHandler";

const EditAccount = (props) => {
  const history = useHistory();
  const fetchedMyAccount = useSelector((state) => state.FetchMyAccount);
  const ManageSubscriptionResult = useSelector((state)=>state.ManageSubscription)
  const MyAccount = useSelector((state) => state.MyAccount);
  const ZuoraPaymentInfo = useSelector((state) => state.ZuoraPaymentInfoRendering);
  const updateShippingResult = useSelector((state) => state.UpdateShipping);
  const [regionUnSupportedZipError,setRegionUnSupportedZipError] = useState(false)
  const [dropdownSelectedCountry, setDropdownSelectedCountry] = useState("")
  const [loading, setLoading] = useState(false);
  const [billingFormLoading,setBillingFormLoading] = useState(false)
  const paymentMethodId = "";
  let shippingAddressI,billingAddressI,shippingAddress,billingAddress;

  useEffect(() => {
   /** This was implemented to avoid page auto scrolling down in some browser scenarios eg:Firefox */
    let selectElement = null;
    if(props.location.state.accountClicked === "shippingAddress" || props.location.state.accountClicked === "billingAddress"){
      selectElement = '.address-edit-form-desktop-app-wrapper'
    } else if(props.location.state.accountClicked === "paymentDetails"){
      selectElement = '.my-acc-payment-container'
    }
    scrollToTop(selectElement);
     // eslint-disable-next-line
  }, [])
  
  if(props.history.action === 'PUSH'){
    shippingAddressI = fetchedMyAccount && fetchedMyAccount.myAccount && fetchedMyAccount.myAccount.shippingAddress && fetchedMyAccount.myAccount.shippingAddress
    billingAddressI = fetchedMyAccount && fetchedMyAccount.myAccount && fetchedMyAccount.myAccount.billingAddress && fetchedMyAccount.myAccount.billingAddress

    shippingAddress = {
      address: shippingAddressI && shippingAddressI.addressLine1 && trimAddressFieldsLength(shippingAddressI.addressLine1, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
      addressLine2: shippingAddressI && shippingAddressI.addressLine2 && trimAddressFieldsLength(shippingAddressI.addressLine2, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
      city: shippingAddressI && shippingAddressI.cityTown && shippingAddressI.cityTown,
      country: shippingAddressI && shippingAddressI.country && shippingAddressI.country,
      postcode: shippingAddressI && shippingAddressI.postCode && shippingAddressI.postCode,
      company: shippingAddressI && shippingAddressI.companyName && shippingAddressI.companyName,
      province: shippingAddressI?.state
    };
  
    billingAddress = {
      address: billingAddressI && billingAddressI.addressLine1 && trimAddressFieldsLength(billingAddressI.addressLine1, BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH),
      addressLine2: billingAddressI && billingAddressI.addressLine2 && trimAddressFieldsLength(billingAddressI.addressLine2, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
      city: billingAddressI && billingAddressI.cityTown && billingAddressI.cityTown,
      country: billingAddressI && billingAddressI.country && billingAddressI.country,
      postcode: billingAddressI && billingAddressI.postCode && billingAddressI.postCode,
      company: billingAddressI && billingAddressI.companyName && billingAddressI.companyName,
      province: billingAddressI?.state,
      eInvoiceId: billingAddressI && billingAddressI.eInvoiceId && billingAddressI.eInvoiceId
    };
  }
  else if(props.history.action === 'POP'){
    shippingAddressI = MyAccount && MyAccount.account && MyAccount.account.shippingAddress
    billingAddressI = MyAccount && MyAccount.account && MyAccount.account.billingAddress

    shippingAddress = {
      address: shippingAddressI && shippingAddressI.address && trimAddressFieldsLength(shippingAddressI.address, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
      addressLine2: shippingAddressI && shippingAddressI.addressLine2 && trimAddressFieldsLength(shippingAddressI.addressLine2, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
      city: shippingAddressI && shippingAddressI.city && shippingAddressI.city,
      country: shippingAddressI && shippingAddressI.country && shippingAddressI.country,
      postcode: shippingAddressI && shippingAddressI.postcode && shippingAddressI.postcode,
      company: shippingAddressI && shippingAddressI.company && shippingAddressI.company,
      province: shippingAddressI?.province
    };
  
    billingAddress = {
      address: billingAddressI && billingAddressI.address && trimAddressFieldsLength(billingAddressI.address, BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH),
      addressLine2: billingAddressI && billingAddressI.addressLine2 && trimAddressFieldsLength(billingAddressI.addressLine2, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
      city: billingAddressI && billingAddressI.city && billingAddressI.city,
      country: billingAddressI && billingAddressI.country && billingAddressI.country,
      postcode: billingAddressI && billingAddressI.postcode && billingAddressI.postcode,
      company: billingAddressI && billingAddressI.company && billingAddressI.company,
      province: billingAddressI?.province,
      eInvoiceId: billingAddressI && billingAddressI.eInvoiceId && billingAddressI.eInvoiceId,
    };
  }
  
  
  const dispatch = useDispatch();
  const [validate, setValidate] = useState(false);

  const submit = (e,formVal, accountClicked, formType) => {
    setRegionUnSupportedZipError(false)
    e.preventDefault()
    let forms = (formType &&  formType === 'web-address-edit-form') 
    ? document.querySelectorAll(".web-needs-validation")
    : document.querySelectorAll(".needs-validation");
    let formValidity, zipError, mobileError;
    

    Array.prototype.slice.call(forms).forEach((form) => {
      if (!form.checkValidity()) {
        formValidity = true;
      }
      formVal.province = validateProvinceData(formVal);
      if (formVal.country && formVal.postcode) {
        if (findzipCodeError(formVal.country, formVal.postcode)) {
          zipError = true;
          dispatch(showZipCodeError(true));
        } else {
          zipError = false;
          dispatch(showZipCodeError(false));
        }
      }
      if (formVal.hasOwnProperty("mobileNumber")) {
        if (
          findMobileError(formVal.mobileNumber) ||
          formVal.mobileNumber.length === 0
        ) {
          mobileError = true;
          dispatch(showMobileError(true));
        } else {
          mobileError = false;
          dispatch(showMobileError(false));
        }
      }
    });

    if (formValidity === true || zipError === true || mobileError === true) {
      setValidate(true);
    } 
    else {
      let formData = MyAccount.account;
      setValidate(false);
      if (accountClicked === "shippingAddress") {
        formData.shippingAddress = formVal;
        formData.personalDetails = {...formData.personalDetails,
          "firstName": (fetchedMyAccount.myAccount.firstName) ? fetchedMyAccount.myAccount.firstName : "",
          "lastName": (fetchedMyAccount.myAccount.lastName) ? fetchedMyAccount.myAccount.lastName : "",
          "emailAddress": (fetchedMyAccount.myAccount.emailAddress) ? fetchedMyAccount.myAccount.emailAddress: "",
          "telephoneNumber": (fetchedMyAccount.myAccount.phoneNumber) ? fetchedMyAccount.myAccount.phoneNumber : "",
          "mobileNumber": (fetchedMyAccount.myAccount.mobileNumber) ? fetchedMyAccount.myAccount.mobileNumber : ""
        }

        const countryCode2 = ENVSettings.COUNTRIES.find(item => item.name === formVal.country);
        const endpoint = _API_ENDPOINT.COUNTRY_POST_CODE;
        const payload = {
          params: {
              countryCode: countryCode2.code2
          }
        }
        setLoading(true)
        _CALLGETAPI(endpoint, payload).then((response) => {
          setLoading(false);
          if(response?.actionStatus?.toUpperCase() === SUCCESS){
            const isRegionNotSupportedForDelivery = checkEcoproSupportRegion(formVal.postcode,response.postCodes)
            if(isRegionNotSupportedForDelivery){
              zipError = true;
              setRegionUnSupportedZipError(true)
              setDropdownSelectedCountry(countryCode2.code2)
              dispatch(showZipCodeError(true));
              
            }
            else{
              const subscriptionPayload = {
                accountDetails: MyAccount.account,
              };
              dispatch(changeAccountStatus("inactive", formData));
              history.push({pathname:routePath.UPDATE_ACCOUNT_CONFIRMATION,
                state:{
                subscriptionPayload:subscriptionPayload,
                accountClicked:"shippingAddress"
              }})
            }
          }
          else{
            const subscriptionPayload = {
              accountDetails: MyAccount.account,
            };
            
            dispatch(changeAccountStatus("inactive", formData));
            history.push({pathname:routePath.UPDATE_ACCOUNT_CONFIRMATION,
              state:{
              subscriptionPayload:subscriptionPayload,
              accountClicked:"shippingAddress"
            }})
          }
        });
      } 
      else if (accountClicked === "billingAddress") {
          formData.billingAddress = formVal;
          formData.personalDetails = {...formData.personalDetails,
            "firstName":  (fetchedMyAccount.myAccount.firstName) ? fetchedMyAccount.myAccount.firstName : "",
            "lastName": (fetchedMyAccount.myAccount.lastName) ? fetchedMyAccount.myAccount.lastName : "",
            "emailAddress": (fetchedMyAccount.myAccount.emailAddress) ? fetchedMyAccount.myAccount.emailAddress: "",
            "telephoneNumber": (fetchedMyAccount.myAccount.phoneNumber) ? fetchedMyAccount.myAccount.phoneNumber : "",
            "mobileNumber": (fetchedMyAccount.myAccount.mobileNumber) ? fetchedMyAccount.myAccount.mobileNumber : ""
          }
          const subscriptionPayload = {
            accountDetails: MyAccount.account,
          };
          const countryCode2 = ENVSettings.COUNTRIES.find(item => item.name === formVal.country);
          const endpoint = _API_ENDPOINT.COUNTRY_POST_CODE;
          const payload = {
            params: {
                countryCode: countryCode2.code2
            }
          }
          setBillingFormLoading(true)
          _CALLGETAPI(endpoint, payload).then((response) => {
            setBillingFormLoading(false)
            if(response?.actionStatus?.toUpperCase() === SUCCESS){
              const isRegionNotSupportedForDelivery = checkEcoproSupportRegion(formVal.postcode,response.postCodes)
              if(isRegionNotSupportedForDelivery){
                dispatch(ManageSubscription(paymentMethodId, subscriptionPayload, SUBSCRIPTION_TYPE.UPDATE_BILLING));
              }
              else{
                history.push({pathname:routePath.UPDATE_ACCOUNT_CONFIRMATION,
                  state:{
                  subscriptionPayload:subscriptionPayload,
                  accountClicked:"billingAddress"
                }})
              }
            }
            else{
              history.push({pathname:routePath.UPDATE_ACCOUNT_CONFIRMATION,
                state:{
                subscriptionPayload:subscriptionPayload,
                accountClicked:"billingAddress"
              }})
            }
          })
      }
    }
  };
 
  if (ZuoraPaymentInfo.loading || updateShippingResult.loading || ManageSubscriptionResult.loading || billingFormLoading) {
    return <Loader></Loader>;
  }
  if(ManageSubscriptionResult.error){
    return <ErrorPage errorInfo={ManageSubscriptionResult.error} showRefreshBtn={refreshBtn.HIDE} showTryAgain={tryAgainBtn.SHOW} to={routePath.MY_ACCOUNT}></ErrorPage>
  }
  if(ZuoraPaymentInfo.error){
    return <ErrorPage errorInfo={ZuoraPaymentInfo.error} showRefreshBtn={refreshBtn.SHOW} ></ErrorPage>
  }
  if(ManageSubscriptionResult.success ){
    return (
      <Redirect to={{pathname:routePath.UPDATE_ACCOUNT_SUCCESS,
      state:{accountClicked:props?.location?.state?.accountClicked}}}></Redirect>
    )
  }
  if (updateShippingResult.error) {
    return (
      <ErrorPage
        errorInfo={updateShippingResult.error}
        showRefreshBtn={refreshBtn.HIDE}
        showTryAgain={tryAgainBtn.SHOW}
        to={routePath.MY_ACCOUNT}
      ></ErrorPage>
    );
  }
  /**
     Modifying the classnames 'address-edit-form-desktop-app-wrapper' and 'my-acc-payment-container' should be updated in 'scrollToTop' method in useEffect
  **/
  if (props.location.state.accountClicked === "shippingAddress") {
    return (
      <EditShipping
        submit={submit}
        validate={validate}
        initialValue={shippingAddress}
        country={ENVSettings.COUNTRIES}
        regionUnSupportedZipError={regionUnSupportedZipError}
        dropdownSelectedCountry={dropdownSelectedCountry}
        showZipError = {MyAccount.showZipError}
        postCodeLoading = {loading}
      />
    );
  } else if (props.location.state.accountClicked === "billingAddress") {
    return (
      <EditBilling
        submit={submit}
        validate={validate}
        initialValue={billingAddress}
        country={ENVSettings.COUNTRIES}
      />
    );
  } 
  else{
    return <ErrorPage></ErrorPage>
  } 
};

export default EditAccount;
