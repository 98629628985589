import React from "react";
import { Row, Progress } from "reactstrap";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EcoProButton from "../shared/button/button";
import currencies from "../../constants/CurrencyTypes";
import "./DashboardAccountOverview.css";
import { getPlanTranslation, getPrinterTypeTranslation, formatNumberOnLocale } from "../../constants/AppMethod";

const DashboardAccountOverview = (props) => {
  let pagesLeftProgressVal = 0,
    pagesRolledOverProgressVal = 0,
    overagesProgressVal = 0;
  const {
    planName,
    planPages,
    planPagesLeft,
    planRolloverPages,
    planRolloverpagesLeft,
    planOveragePages,
    planOveragesPagesLeft,
    planCurrency,
    planPrice,
    deviceImagePath,
    deviceModel,
    deviceSerialNumber,
    moveToSwapDevice,
    isSwapEligible,
    overageBlockPages,
    deviceInfoLoader,
    printerType,
    cname
  } = props;
  const { t } = useTranslation(["translation"]);
  const settings = {
    infinite: false,
    speed: 500,
    dots: true,
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  pagesLeftProgressVal = (planPagesLeft * 100) / planPages || 0;
  pagesRolledOverProgressVal =
    (planRolloverpagesLeft * 100) / planRolloverPages || 0;
  overagesProgressVal = (planOveragesPagesLeft * 100) / planOveragePages || 0;

  return (
    <div className={`account-info-wrapper ${cname}`}>
      {/**
      * This section display change device block only on mobile devices,
        if the customer is eligible for swap
      */}
      {isSwapEligible === true && !deviceInfoLoader ? (
        <div className="dashboard-display-device show-xs">
          <div className="f-16 ta-left fw-bold title">
            {t("DASHBOARD.DISPLAY_DEVICE.YOUR_DEVICE")}
          </div>
          <div className="display-device-content">
            <div className={`device-img ${deviceImagePath ? "":"image-background"}`}>
              {deviceInfoLoader ? (
                <Skeleton
                  square
                  width="150px"
                  height="150px"
                  className="display-device-img-skeleton"
                />
              ) : (
                <img src={deviceImagePath} alt="device" />
              )}
            </div>
            {deviceInfoLoader ? (
              <>
                <Skeleton className="para-skeleton1" />
                <Skeleton className="para-skeleton2" />
              </>
            ) : (
              <>
                <div className="f-20 fw-b mt-10">{deviceModel}</div>
                <div className="display-device f-12">
                  {t("DASHBOARD.DISPLAY_DEVICE.SERIAL_NUMBER")}:{" "}
                  {deviceSerialNumber}
                </div>
              </>
            )}
          </div>
          <div className="change-device-btn">
            <EcoProButton cname="default" onBtnClick={() => moveToSwapDevice()}>
              {t("DASHBOARD.DISPLAY_DEVICE.CHANGE_DEVICE")}
            </EcoProButton>
          </div>
        </div>
      ) : null}

      {/**
      * MOBILE VERSION: This section is account overview section displayed only on mobile devices.
      */}
      <div className="account-info-carousel-view show-xs">
        <Slider {...settings}>
          <div className="pages-left col-lg-7">
            <h4 className="fc-g pages-left-sm">{t("DASHBOARD.PAGES_LEFT")}</h4>
            {deviceInfoLoader ? (
              <div className="circular-skeleton-flex">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <CircularProgressbarWithChildren
                value={pagesLeftProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">{planPagesLeft}</span>
                <span className="pages-leftover-count">/{planPages}</span>
              </CircularProgressbarWithChildren>
            )}
            {deviceInfoLoader ? (
              <>
                <Skeleton className="para-skeleton1" />
              </>
            ) : (
              <p>
                {getPlanTranslation(planName)} {getPrinterTypeTranslation(printerType)}:{" "}
                {t("DASHBOARD.PAGES_PER_MONTH", { plan_pages: planPages })}
              </p>
            )}
          </div>
          <div className="pages-left col-lg-7">
            <h4 className="fc-g pages-left-sm">{t("DASHBOARD.PAGES_ROLLED_OVER")}</h4>
            {deviceInfoLoader ? (
              <div className="circular-skeleton-flex">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <CircularProgressbarWithChildren
                value={pagesRolledOverProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">
                  {planRolloverpagesLeft}
                </span>
                <span className="pages-leftover-count">
                  /{planRolloverPages}
                </span>
              </CircularProgressbarWithChildren>
            )}
          </div>
          <div className="pages-left col-lg-7">
            <h4 className="fc-g pages-left-sm">{t("DASHBOARD.OVERAGES")}</h4>
            {deviceInfoLoader ? (
              <div className="circular-skeleton-flex">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <CircularProgressbarWithChildren
                value={overagesProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">
                  {planOveragesPagesLeft}
                </span>
                <span className="pages-leftover-count">
                  /{planOveragePages}
                </span>
              </CircularProgressbarWithChildren>
            )}
            {deviceInfoLoader ? (
              <>
                <Skeleton className="para-skeleton1" />
              </>
            ) : (
              <>
                {planCurrency && (
                  <p>
                    {t("DASHBOARD.PAGES_PRICE", {
                      plan_pages: overageBlockPages,
                      plan_price: formatNumberOnLocale(planPrice, currencies[planCurrency]?.code),
                    })}
                  </p>
                )}
                {!planCurrency && (
                  <p>
                    {t("DASHBOARD.PAGES_PRICE", {
                      plan_pages: overageBlockPages,
                      plan_price: formatNumberOnLocale(planPrice),
                    })}
                  </p>
                )}
              </>
            )}
          </div>
        </Slider>
      </div>

      {/**
      * DESKTOP VERSION: This section is account overview section displayed only on    tablet landscape orientation and large devices other than mobile if the customer is not eligible for swap.
      */}
      { !isSwapEligible && <div className="account-info-normal-view show-lg dashboard-overview-without-swap-device">
        <Row xs="2">
          <div className="pages-left col-lg-7">
            <h4 className="fc-g pages-left-lg">{t("DASHBOARD.PAGES_LEFT")}</h4>

            {deviceInfoLoader ? (
              <div className="circular-skeleton-flex">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <CircularProgressbarWithChildren
                value={pagesLeftProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">{planPagesLeft}</span>
                <span className="pages-leftover-count">/{planPages}</span>
              </CircularProgressbarWithChildren>
            )}
            {deviceInfoLoader ? (
              <>
                <Skeleton className="para-skeleton1" />
                <Skeleton className="para-skeleton2" />
              </>
            ) : (
              <p>
                {getPlanTranslation(planName)} {getPrinterTypeTranslation(printerType)}:{" "}
                {t("DASHBOARD.PAGES_PER_MONTH", { plan_pages: planPages })}
              </p>
            )}
          </div>
          <div className="overages-wrapper col-lg-5">
            <div className="pages-rolled-over">
              <div className="header">
                <h4 className="fc-g">{t("DASHBOARD.PAGES_ROLLED_OVER")}</h4>
              </div>
              <div className="pages-rolled-over-progress">
                {deviceInfoLoader ? (
                  <Skeleton width="10%" />
                ) : (
                  <p>
                    {planRolloverpagesLeft}/{planRolloverPages}
                  </p>
                )}
                {deviceInfoLoader ? (
                  <Skeleton className="rolled-over-overages-skeleton" />
                ) : (
                  <Progress value={pagesRolledOverProgressVal} />
                )}
              </div>
            </div>
            <div className="pages-overages-wrapper">
              <div className="header">
                <h4 className="fc-g">{t("DASHBOARD.OVERAGES")}</h4>
                {planCurrency && (
                  <span>
                    {t("DASHBOARD.PAGES_PRICE", {
                      plan_pages: overageBlockPages,
                      plan_price: formatNumberOnLocale(planPrice, currencies[planCurrency]?.code),
                    })}
                  </span>
                )}
                {!planCurrency &&
                  (deviceInfoLoader ? null : (
                    <span>
                      {t("DASHBOARD.PAGES_PRICE", {
                        plan_pages: overageBlockPages,
                        plan_price: formatNumberOnLocale(planPrice),
                      })}
                    </span>
                  ))}
              </div>
              <div className="pages-rolled-over-progress">
                {deviceInfoLoader ? (
                  <Skeleton width="10%" />
                ) : (
                  <p>
                    {planOveragesPagesLeft}/{planOveragePages}
                  </p>
                )}
                {deviceInfoLoader ? (
                  <Skeleton className="rolled-over-overages-skeleton" />
                ) : (
                  <Progress value={overagesProgressVal} />
                )}
              </div>
            </div>
          </div>
        </Row>
      </div> }

     { /**
      * DESKTOP VERSION: This section is account overview section displayed only on tablet landscape
        orientation and large devices other than mobile if the customer is eligible for swap.
      */}
      { isSwapEligible === true && <div className="account-info-normal-view show-lg dashboard-overview-with-swap-device">
        <Row>
        { 
          deviceInfoLoader && 
          <div className="dashboard-display-device swap-device-desktop-app col-5">
              <div className="f-16 ta-left fw-bold title">
                {t("DASHBOARD.DISPLAY_DEVICE.YOUR_DEVICE")}
              </div>
              <div className="display-device-content">
                <div className="device-img">
                  <Skeleton square width="150px" height="150px" className="display-device-img-skeleton" />
                </div>
                <>
                  <Skeleton className="para-skeleton1" />
                  <Skeleton className="para-skeleton2" />
                </>
              </div>
          </div>
        }
        {isSwapEligible === true && !deviceInfoLoader ? (
            <div className="dashboard-display-device swap-device-desktop-app col-5">
              <div className="f-16 ta-left fw-bold title">
                {t("DASHBOARD.DISPLAY_DEVICE.YOUR_DEVICE")}
              </div>
              <div className="display-device-content">
                <div className={`device-img ${deviceImagePath ? "":"image-background"}`}>
                  {deviceInfoLoader ? (
                    <Skeleton
                      square
                      width="150px"
                      height="150px"
                      className="display-device-img-skeleton"
                    />
                  ) : (
                    <img src={deviceImagePath} alt="device" />
                  )}
                </div>
                {deviceInfoLoader ? (
                  <>
                    <Skeleton className="para-skeleton1" />
                    <Skeleton className="para-skeleton2" />
                  </>
                ) : (
                  <>
                    <div className="f-20 fw-b mt-10">{deviceModel}</div>
                    <div className="display-device f-12">
                      {t("DASHBOARD.DISPLAY_DEVICE.SERIAL_NUMBER")}:{" "}
                      {deviceSerialNumber}
                    </div>
                  </>
                )}
              </div>
              <div className="change-device-btn">
                <EcoProButton cname="default" onBtnClick={() => moveToSwapDevice()}>
                  {t("DASHBOARD.DISPLAY_DEVICE.CHANGE_DEVICE")}
                </EcoProButton>
              </div>
            </div>
          ) : null}
          <div className="pages-left swap-device-desktop-app col-5">
            <h4 className="fc-g pages-left-lg">{t("DASHBOARD.PAGES_LEFT")}</h4>

            {deviceInfoLoader ? (
              <div className="circular-skeleton-flex">
                <Skeleton
                  circle
                  className="circular-skeleton"
                  height="108px"
                  width="108px"
                  duration={0.9}
                />
              </div>
            ) : (
              <CircularProgressbarWithChildren
                value={pagesLeftProgressVal}
                styles={buildStyles({
                  pathColor: "#008393",
                })}
              >
                <span className="pages-consumed-count">{planPagesLeft}</span>
                <span className="pages-leftover-count">/{planPages}</span>
              </CircularProgressbarWithChildren>
            )}
            {deviceInfoLoader ? (
              <>
                <Skeleton className="para-skeleton1" />
                <Skeleton className="para-skeleton2" />
              </>
            ) : (
              <p>
                {getPlanTranslation(planName)} {getPrinterTypeTranslation(printerType)}:{" "}
                {t("DASHBOARD.PAGES_PER_MONTH", { plan_pages: planPages })}
              </p>
            )}
          </div>
          <div className="overages-wrapper row">
            <div className="pages-rolled-over col-5">
              <div className="header">
                <h4 className="fc-g">{t("DASHBOARD.PAGES_ROLLED_OVER")}</h4>
              </div>
              <div className="pages-rolled-over-progress">
                {deviceInfoLoader ? (
                  <Skeleton width="10%" />
                ) : (
                  <p>
                    {planRolloverpagesLeft}/{planRolloverPages}
                  </p>
                )}
                {deviceInfoLoader ? (
                  <Skeleton className="rolled-over-overages-skeleton" />
                ) : (
                  <Progress value={pagesRolledOverProgressVal} />
                )}
              </div>
            </div>
            <div className="pages-overages-wrapper col-5">
              <div className="header">
                <h4 className="fc-g">{t("DASHBOARD.OVERAGES")}</h4>
                {planCurrency && (
                  <span>
                    {t("DASHBOARD.PAGES_PRICE", {
                      plan_pages: overageBlockPages,
                      plan_price: formatNumberOnLocale(planPrice, currencies[planCurrency]?.code),
                    })}
                  </span>
                )}
                {!planCurrency &&
                  (deviceInfoLoader ? null : (
                    <span>
                      {t("DASHBOARD.PAGES_PRICE", {
                        plan_pages: overageBlockPages,
                        plan_price: formatNumberOnLocale(planPrice),
                      })}
                    </span>
                  ))}
              </div>
              <div className="pages-rolled-over-progress">
                {deviceInfoLoader ? (
                  <Skeleton width="10%" />
                ) : (
                  <p>
                    {planOveragesPagesLeft}/{planOveragePages}
                  </p>
                )}
                {deviceInfoLoader ? (
                  <Skeleton className="rolled-over-overages-skeleton" />
                ) : (
                  <Progress value={overagesProgressVal} />
                )}
              </div>
            </div>
          </div>
        </Row>
      </div> }
    </ div>
  );
};

export default DashboardAccountOverview;
