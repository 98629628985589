import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { fetchDeviceList } from "../../actions/SelectDevice";
import ErrorPage from "../../components/shared/errorPage/ErrorPage";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, refreshBtn, SUBSCRIPTIONKIND } from "../../constants/AppConstants";
import Loader from "../../components/shared/loader/Loader";
import { getUserSessionData, setLocalSessionData } from "../../utils/Authentication/handleUserSession";
import { routePath } from "../../constants/Routes";
import PaymentComplete from "../PaymentComplete/PaymentComplete";
import { getSignupTypeBy_SubscriptionKind } from "../../constants/AppMethod";

const CustomerRedirection = (props) => {
  const { t } = useTranslation(["translation"]);
  const { RouterLinkStateProp }  = props;
  const history = useHistory();
  const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const dispatch = useDispatch();
  const deviceListResult = useSelector((state) => state.SelectDevice);
  const UserAccount = useSelector((state) => state.UserAccount);

  const setSignupType = () => {
    const signUpType = getSignupTypeBy_SubscriptionKind(UserAccount.userAccount.subscriptionKind);
    setLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE, signUpType);
  }
  useEffect(() => {
    if(UserAccount.userAccount.subscriptionKind === SUBSCRIPTIONKIND.WEB
      || UserAccount.userAccount.subscriptionKind === SUBSCRIPTIONKIND.HARDWARE_INCLUSIVE
    ){
        setSignupType();
        history.push(routePath.CONNECT_DEVICE);
    } else if(UserAccount.userAccount.businessPartnerId){
        dispatch(fetchDeviceList(UID));
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, UID]);

  if(!UserAccount.userAccount.businessPartnerId){
    return <ErrorPage errorInfo={t('ERROR_CONTENT.CANT_CREATE_SUBSCRIPTION')}></ErrorPage>
  }

  if (deviceListResult.loading) {
    return <Loader></Loader>;
  }
  if (deviceListResult.error) {
    return (
      <ErrorPage
        errorInfo={deviceListResult.error}
        showRefreshBtn={refreshBtn.HIDE}
      ></ErrorPage>
    );
  }
  if (deviceListResult.deviceList?.devices && deviceListResult.deviceList?.devices.length === 0) {
    return (
      <ErrorPage
        errorInfo={t("ERROR_CONTENT.NO_DEVICES")}
        showRefreshBtn={refreshBtn.HIDE}
      ></ErrorPage>
    );
  }
 
  if (deviceListResult.success === true && deviceListResult.deviceList?.devices) {
        const currentPlan = Object.values(deviceListResult.deviceList.devices).filter((val) => {
          return (val.hasOwnProperty('service') && val.service) ? val.service.currentPlan : "";
        })
        if (currentPlan.length > 0) {
          if(RouterLinkStateProp && RouterLinkStateProp === 'SUBSCRIPTION_NETWORK_ERR_VALIDATION'){
            return <PaymentComplete isSubscriptionCompleted = {true}></PaymentComplete>
          } else {
            const pathHistory = JSON.parse(sessionStorage.getItem('USER_PATH_HISTORY'));
            if(pathHistory && pathHistory['path'] && pathHistory['isSubscribed'] === true){
              return <Redirect to={pathHistory['path'] }></Redirect>
            } else{
              return <Redirect to={routePath.DASHBOARD}></Redirect>
            }
          }
        } else if (deviceListResult.deviceList?.devices.length === 1) {
          return <Redirect to={{ pathname: routePath.PLANS,
            state: {
              'deviceId': deviceListResult.deviceList?.devices[0].deviceId,
              'device_model': deviceListResult.deviceList?.devices[0].model,
              'serialNumber': deviceListResult.deviceList?.devices[0].serialNumber,
              'networkStatus': deviceListResult.deviceList?.devices[0].networkStatus,
              'deviceLength':"1"
            }
            }}></Redirect>
        }
  } 
  if (
    deviceListResult.success === true &&
    deviceListResult.deviceList?.devices.length > 1 &&
    deviceListResult.deviceList?.devices.map((device) => device.hasOwnProperty("model"))
  ) {
    return <Redirect to={{ pathname: routePath.CONFIRM_DEVICE, state: { deviceListResult: deviceListResult }}}></Redirect>
  } 
  return <Loader></Loader>
};

export default CustomerRedirection;
