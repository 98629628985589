import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { getPlanTranslation, getPrinterTypeTranslation } from "../../../constants/AppMethod";
import { DisplayDate } from "../../../utils/DisplayDate";
import PagesUsedLableIcon from "../../../assets/images/print-activity-pages-used-label-icon.svg";
import MonthlyAllowanceLableIcon from "../../../assets/images/print-activity-monthly-allowance-icon.svg";
import RolloverLabelIcon from "../../../assets/images/print-activity-rollover.svg";
import OverageLabelIcon from "../../../assets/images/print-activity-overage.svg"
import { getLangCode } from "../../../utils/Internationalization/handleAppInternationalization";
import "./PagesUsed.css";
import HalfDoughnutChart from "../HalfDoughnut";

const PagesUsed = (props) => {
    const {
        planName = null,
        planPages = 0,
        printedPlanPages = 0,
        printedTotalPages= 0,
        printedRolloverPages= 0,
        givenRolloverPages= 0,
        printedOveragePages= 0,
        purchasedOveragePages= 0,
        printerType=null,
        dateDropdownArr = [],
        dateFilter,
        IS_CSAGENT
    } = props;
    const pageTranslation = (IS_CSAGENT) ? "CSAgenttranslation" : "translation";
    const { t } = useTranslation([pageTranslation]);
    const langcode = (IS_CSAGENT) ? 'en' : getLangCode();
    const dateDisplayOptions = { month: "long", year: "numeric"};
    return (
        <Container className="pages-used-outer-wrapper mt-15">
            <div className="pages-used-inner-wrapper">
                <div className="subscription-month-dropdown-wrapper">
                    <select className="subscription-month-dropdown" onChange={({ target: { value } }) => dateFilter(value)}>
                        {
                            dateDropdownArr && dateDropdownArr.length > 0 && dateDropdownArr.map((date) => (
                                <option value={date} key={date}>{DisplayDate(date, dateDisplayOptions, langcode)}</option>
                            ))
                        }
                    </select>
                </div>
                <div>
                    <div className="pages-used-data-representation-wrapper">
                            <HalfDoughnutChart
                                printedTotalPages = {printedTotalPages}
                                pagesUsed = {printedPlanPages}
                                rolloverAllowance = {printedRolloverPages}
                                overage = {printedOveragePages}
                                planPages = {planPages}
                                givenRolloverPages = {givenRolloverPages}
                                purchasedOveragePages = {purchasedOveragePages}
                                IS_CSAGENT = {IS_CSAGENT}
                            ></HalfDoughnutChart>
                    </div>
                    <div className="pages-used-progressbar-labels-wrapper">
                        <div className="pages-used-label label">
                            <img src={PagesUsedLableIcon} alt="pages-used" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.PAGES_USED')}</p>
                        </div>
                        <div className="monthly-allowance-label label">
                            <img src={MonthlyAllowanceLableIcon} alt="monthly-allowance" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.MONTHLY_ALLOWANCE')}</p>
                        </div>
                        <div className="pages-used-label label">
                            <img src={RolloverLabelIcon} alt="roll-over" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.ROLLOVER')}</p>
                        </div>
                        <div className="monthly-allowance-label label">
                            <img src={OverageLabelIcon} alt="monthly-allowance" />
                            <p>{t('PRINT_ACTIVITY.PAGES_USED_LABELS.OVERAGE')}</p>
                        </div>
                    </div>
                    <div className="pages-used-plan-details-wrapper">
                        <p>{t('PRINT_ACTIVITY.PLAN_TYPE')}</p>
                        {
                            (IS_CSAGENT) 
                            ? <p className="plan-name">{t('PRINT_ACTIVITY.PLAN_NAME', { plan_name: t(`PLAN_NAMES.${planName}`), printer_type: t(`PRINTER_TYPES.${printerType?.toUpperCase().split(" ").join("_")}`), plan_pages: planPages })}</p> 
                            : <p className="plan-name">{t('PRINT_ACTIVITY.PLAN_NAME', { plan_name: getPlanTranslation(planName), printer_type: getPrinterTypeTranslation(printerType), plan_pages: planPages })}</p> 
                        }
                    </div>
                </div>
            </div>
        
        </Container>
    )
}

export default PagesUsed;