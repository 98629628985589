export const DASHBOARD_DEVICE_INFO_CALL_INIT = 'DASHBOARD_DEVICE_INFO_CALL_INIT';
export const DASHBOARD_DEVICE_INFO_CALL_SUCCESS = 'DASHBOARD_DEVICE_INFO_CALL_SUCCESS';
export const DASHBOARD_DEVICE_INFO_CALL_ERROR = 'DASHBOARD_DEVICE_INFO_CALL_ERROR';

export const DASHBOARD_ORDER_HISTORY_CALL_INIT = 'DASHBOARD_ORDER_HISTORY_CALL_INIT';
export const DASHBOARD_ORDER_HISTORY_CALL_SUCCESS = 'DASHBOARD_ORDER_HISTORY_CALL_SUCCESS';
export const DASHBOARD_ORDER_HISTORY_CALL_ERROR = 'DASHBOARD_ORDER_HISTORY_CALL_ERROR';

export const DOWNLOAD_ORDER_INVOICE = 'DOWNLOAD_ORDER_INVOICE';
export const DOWNLOAD_ORDER_INVOICE_SUCCESS = 'DOWNLOAD_ORDER_INVOICE_SUCCESS';
export const DOWNLOAD_ORDER_INVOICE_ERROR = 'DOWNLOAD_ORDER_INVOICE_ERROR';

export const FETCH_MY_ACCOUNT = 'FETCH_MY_ACCOUNT'
export const FETCH_MY_ACCOUNT_SUCCESS = 'FETCH_MY_ACCOUNT_SUCCESS'
export const FETCH_MY_ACCOUNT_ERROR = 'FETCH_MY_ACCOUNT_ERROR'

export const UPDATE_SHIPPING_ADDR = 'UPDATE_SHIPPING_ADDR'
export const UPDATE_SHIPPING_ADDR_SUCCESS = 'UPDATE_SHIPPING_ADDR_SUCCESS'
export const UPDATE_SHIPPING_ADDR_ERROR = 'UPDATE_SHIPPING_ADDR_ERROR'

export const CANCEL_ORDER = 'CANCEL_ORDER'
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR'

export const GET_CANCELLATION_FEES = 'GET_CANCELLATION_FEES'
export const GET_CANCELLATION_FEES_SUCCESS = 'GET_CANCELLATION_FEES_SUCCESS'
export const GET_CANCELLATION_FEES_ERROR = 'GET_CANCELLATION_FEES_ERROR'

export const SWAP_DEVICE = 'SWAP_DEVICE'
export const SWAP_DEVICE_SUCCESS = 'SWAP_DEVICE_SUCCESS'
export const SWAP_DEVICE_ERROR = 'SWAP_DEVICE_ERROR'
export const RESET_SWAP = 'RESET_SWAP'

export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_ERROR = 'UPDATE_PLAN_ERROR';

export const SIGNUP_USER_FEEDBACK = 'SIGNUP_USER_FEEDBACK'
export const SIGNUP_USER_FEEDBACK_SUCCESS = 'SIGNUP_USER_FEEDBACK_SUCCESS'
export const SIGNUP_USER_FEEDBACK_ERROR = 'SIGNUP_USER_FEEDBACK_ERROR'

export const SIGNUP_USER_FEEDBACK_ERROR_DESKTOP = 'SIGNUP_USER_FEEDBACK_ERROR_DESKTOP'
export const SIGNUP_USER_FEEDBACK_SUCCESS_DESKTOP = 'SIGNUP_USER_FEEDBACK_SUCCESS_DESKTOP'

export const GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY = 'GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY'
export const GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_SUCCESS = 'GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_SUCCESS'
export const GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_ERROR = 'GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_ERROR'

export const STORE_FEEDBACK = 'STORE_FEEDBACK'

export const RESET_STATE = 'RESET_STATE'

export const UPDATE_PLAN_RESET = 'UPDATE_PLAN_RESET'

export const ENROLLMENT_STATUS_CHECK = 'ENROLLMENT_STATUS_CHECK'

export const ASYNC_ENROLLMENT_RETRY_INIT = 'ASYNC_ENROLLMENT_RETRY_INIT'
export const ASYNC_ENROLLMENT_RETRY_SUCCESS = 'ASYNC_ENROLLMENT_RETRY_SUCCESS'
export const ASYNC_ENROLLMENT_RETRY_ERROR = 'ASYNC_ENROLLMENT_RETRY_ERROR'

export const GET_USER_SUBSCRIPTION = 'GET_USER_SUBSCRIPTION'
export const GET_USER_SUBSCRIPTION_SUCCESS = 'GET_USER_SUBSCRIPTION_SUCCESS'
export const GET_USER_SUBSCRIPTION_ERROR = 'GET_USER_SUBSCRIPTION_ERROR'
