import React, {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { GetCancellationFee, UpdateShippingAddress } from '../../../actions/Dashboard';
import { ManageSubscription } from '../../../actions/MyAccount';
import ErrorPage from '../../../components/shared/errorPage/ErrorPage';
import Loader from '../../../components/shared/loader/Loader';
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, refreshBtn, SUBSCRIPTION_TYPE, tryAgainBtn, UNIQUE_ID_GA } from '../../../constants/AppConstants';
import { routePath } from '../../../constants/Routes';
import warning from '../../../assets/images/warning.svg';
import DisplayUpdateAccountConfirmation from '../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation';
import updateShippingAddrsConfirmationIcon from '../../../assets/images/update-shipping-as-billing-confirmation-icon.svg';
import updateBillingAddrsConfirmationIcon from '../../../assets/images/update-billing-as-shipping-confirmation-icon.svg';
import { formatNumberOnLocale, verifyItalyBusinessUser } from '../../../constants/AppMethod';
import currencies from '../../../constants/CurrencyTypes';
import { getLocalSessionData, getUserSessionData } from '../../../utils/Authentication/handleUserSession';

const UpdateAccountConfirmation = (props) => {
  const history = useHistory();
  const paymentMethodId = "";
  const { t } = useTranslation(["translation"]);
  const uid = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
  const [updateSubmitStatus, setUpdateSubmitStatus] = useState(false);
  const [rejectSubmitStatus, setRejectSubmitStatus] = useState(false);
  const dispatch = useDispatch();
  const ManageSubscriptionResult = useSelector((state)=>state.ManageSubscription)
  const updateShippingResult = useSelector((state) => state.UpdateShipping);
  const MyAccount = useSelector((state) => state.MyAccount);
  const ZuoraPaymentInfo = useSelector(
    (state) => state.ZuoraPaymentInfoRendering
  );
  const [shippingState,setShippingState] = useState(null)
  const [billingState, setBillingState] = useState(null);
  const cancellationFeesResult = useSelector((state) => state.GetCancellationFees)
  const [cancellationFee, setCancellationFee] = useState({
    price:"",
  })
  
  useEffect(()=>{
    if(props.location.state.accountClicked === 'cancelPlan'){
      const minimumTermEndData = getLocalSessionData(LOCALSESSIONDATAKEYS.TERM_END_DATE)
      if(minimumTermEndData){
        dispatch(GetCancellationFee(uid, deviceId))
      }
    }
   },[deviceId, dispatch, props?.location?.state?.accountClicked, uid])

   useEffect(() => {
    if (cancellationFeesResult?.success) {
        const cancellationFeeObj = cancellationFeesResult?.cancellationFee;
        if (cancellationFeeObj) {
            const { cancellationFee, currency } = cancellationFeeObj;
            const currencySymbol = currencies[currency]?.code;

            setCancellationFee(prevSelections => ({
                ...prevSelections,
                price: formatNumberOnLocale(cancellationFee, currencySymbol),
            }));
        }
    }
}, [cancellationFeesResult?.success, cancellationFeesResult?.cancellationFee]);

  const onReject = () => {
      if (props.location.state.accountClicked === "shippingAddress") {
        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(UpdateShippingAddress(subscriptionPayload));
        setRejectSubmitStatus(true)
      } else if (props.location.state.accountClicked === "billingAddress") {
        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(ManageSubscription(paymentMethodId, subscriptionPayload, SUBSCRIPTION_TYPE.UPDATE_BILLING));
        setRejectSubmitStatus(true)
      }
    };
    
    if(updateShippingResult.success && shippingState === true){
        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(ManageSubscription(paymentMethodId, subscriptionPayload, SUBSCRIPTION_TYPE.UPDATE_BILLING));
        setShippingState(false)
    }

    if(ManageSubscriptionResult.success && billingState === true){
      const subscriptionPayload = {
        accountDetails: MyAccount.account,
      };
      dispatch(UpdateShippingAddress(subscriptionPayload));
      setBillingState(false)
    }
    
    const onUpdate = () => {
      if (props.location.state.accountClicked === "shippingAddress") {
        const isItalyBusinessUser = verifyItalyBusinessUser(MyAccount?.account.billingAddress.country)
        if(isItalyBusinessUser){
          if(!(MyAccount?.account?.shippingAddress?.company)){
            MyAccount.account.billingAddress = {
              address: MyAccount?.account?.shippingAddress?.address,
              addressLine2: MyAccount?.account?.shippingAddress?.addressLine2,
              city: MyAccount?.account?.shippingAddress?.city,
              country: MyAccount?.account?.shippingAddress?.country,
              postcode: MyAccount?.account?.shippingAddress?.postcode,
              company: MyAccount?.account?.billingAddress?.company,
              province: MyAccount?.account?.shippingAddress?.province,
              eInvoiceId: MyAccount?.account?.billingAddress?.eInvoiceId
            }
          }
          else{
            MyAccount.account.billingAddress = {
              address: MyAccount?.account?.shippingAddress?.address,
              addressLine2: MyAccount?.account?.shippingAddress?.addressLine2,
              city: MyAccount?.account?.shippingAddress?.city,
              country: MyAccount?.account?.shippingAddress?.country,
              postcode: MyAccount?.account?.shippingAddress?.postcode,
              company: MyAccount?.account?.shippingAddress?.company,
              province: MyAccount?.account?.shippingAddress?.province,
              eInvoiceId: MyAccount?.account?.billingAddress?.eInvoiceId
            }
          }
        }
        else{
          MyAccount.account.billingAddress = MyAccount.account.shippingAddress;
        }

        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(UpdateShippingAddress(subscriptionPayload))
        setShippingState(true)
        setUpdateSubmitStatus(true)
      }
      if (props.location.state.accountClicked === "billingAddress") {
        MyAccount.account.shippingAddress = MyAccount.account.billingAddress;
        const subscriptionPayload = {
          accountDetails: MyAccount.account,
        };
        dispatch(ManageSubscription(paymentMethodId, subscriptionPayload, SUBSCRIPTION_TYPE.UPDATE_BILLING));
        setBillingState(true)
        setUpdateSubmitStatus(true)
      }        
    };
    const onConfirmCancelPlan = () => {
      history.push({
        pathname:routePath.CANCEL_PLAN_FEEDBACK,
        state:{'customerConfirmStatus': true}})
    }
    const onStopCancellation = () => {
      history.push(routePath.DASHBOARD)
    }
        
    if (ZuoraPaymentInfo.loading || updateShippingResult.loading || ManageSubscriptionResult.loading || cancellationFeesResult.loading) {
      return <Loader></Loader>;
    }
    if(ManageSubscriptionResult.error){
      return <ErrorPage errorInfo={ManageSubscriptionResult.error} showRefreshBtn={refreshBtn.HIDE} showTryAgain={tryAgainBtn.SHOW} to={routePath.MY_ACCOUNT}></ErrorPage>
    }
    if(ZuoraPaymentInfo.error){
      return <ErrorPage errorInfo={ZuoraPaymentInfo.error} showRefreshBtn={refreshBtn.SHOW} ></ErrorPage>
    }
    if (updateShippingResult.error) {
      return (
        <ErrorPage
          errorInfo={updateShippingResult.error}
          showRefreshBtn={refreshBtn.HIDE}
          showTryAgain={tryAgainBtn.SHOW}
          to={routePath.MY_ACCOUNT}
        ></ErrorPage>
      );
    }
    if (props.location.state.accountClicked === "shippingAddress" && updateSubmitStatus === false && rejectSubmitStatus === false) {
      return(
        <DisplayUpdateAccountConfirmation
          updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_HEADER.SHIPPING_SAME")}
          updateAddressInfo={t("EDIT_FORM.UPDATE_ACCOUNT_INFO.SHIPPING_SAME")}
          updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.SHIPPING_SAME")}
          onUpdateClick={() => onUpdate()}
          onRejectClick={() => onReject()}
          noThanksStatus={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.NO_THANKS")}
          image={updateBillingAddrsConfirmationIcon}
          onUpdateBtnID={UNIQUE_ID_GA.myAccount+"-update-both-as-shipping"}
          onRejectBtnID={UNIQUE_ID_GA.myAccount+"-reject-billing"}
        ></DisplayUpdateAccountConfirmation>
      )
    }
    else if (props.location.state.accountClicked === "billingAddress" && updateSubmitStatus === false && rejectSubmitStatus === false) {
      return(
        <DisplayUpdateAccountConfirmation
          updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_HEADER.BILLING_SAME")}
          updateAddressInfo={t("EDIT_FORM.UPDATE_ACCOUNT_INFO.BILLING_SAME")}
          updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.BILLING_SAME")}
          onUpdateClick={() => onUpdate()}
          onRejectClick={() => onReject()}
          noThanksStatus={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.NO_THANKS")}
          image={updateShippingAddrsConfirmationIcon}
          onUpdateBtnID={UNIQUE_ID_GA.myAccount+"-update-both-as-billing"}
          onRejectBtnID={UNIQUE_ID_GA.myAccount+"-reject-shipping"}
        ></DisplayUpdateAccountConfirmation>
      )
    }
    else if(props.location.state.accountClicked === 'cancelPlan'){
      return (
        <DisplayUpdateAccountConfirmation
          updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_HEADER.CANCEL_PLAN")}
          updateAddressInfo={t("EDIT_FORM.UPDATE_ACCOUNT_INFO.CANCEL_PLAN")}
          subInfo={getLocalSessionData(LOCALSESSIONDATAKEYS.TERM_END_DATE) ? t("EDIT_FORM.UPDATE_ACCOUNT_INFO.MINIMUM_TERM_PERIOD_CANCELLATION", {price:cancellationFee?.price}) : null}
          updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CANCEL_PLAN")}
          onUpdateClick={() => onConfirmCancelPlan()}
          onRejectClick={() => onStopCancellation()}
          noThanksStatus={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.STOP_CANCEL_PLAN")}
          image={warning}
        ></DisplayUpdateAccountConfirmation>
      )
    }
    else if (updateSubmitStatus === true && updateShippingResult.success ===  true && ManageSubscriptionResult.success === true) {
      return (
        <Redirect to={{pathname:routePath.UPDATE_ACCOUNT_SUCCESS,
        state:{accountClicked:"shippingBillingAddress"}}}></Redirect>
      )
    }
    else if(rejectSubmitStatus === true && props.location.state.accountClicked === "shippingAddress" && updateShippingResult.success){
      return (
        <Redirect to={{pathname:routePath.UPDATE_ACCOUNT_SUCCESS,
        state:{accountClicked:"shippingAddress"}}} ></Redirect>
      )
    }
    else if(rejectSubmitStatus === true && props.location.state.accountClicked === "billingAddress" && ManageSubscriptionResult.success){
      return (
        <Redirect to={{pathname:routePath.UPDATE_ACCOUNT_SUCCESS,
        state:{accountClicked:"billingAddress"}}}></Redirect>
      )
    }
    else{
      return (
        <ErrorPage/>
      )
    }
  };

export default UpdateAccountConfirmation;
