import React, { useEffect } from "react";
import "./MyAccount.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ManageUpdateShippingResetState, FetchMyAccount, FetchDeviceInfo, UpdatePlanReset } from "../../../actions/Dashboard";
import { ManageSubscriptionResetState } from "../../../actions/MyAccount";
import PersonalDetails from "../../../components/DisplayMyAccount/PersonalDetails";
import ShippingAndBillingAddress from "../../../components/DisplayMyAccount/ShippingAndBillingAddress";
import MyPlan from "../../../components/DisplayMyAccount/MyPlan";
import LegalDocuments from "../../../components/DisplayMyAccount/LegalDocuments";
import UpdateOrCancelCard from "../../../components/DisplayMyAccount/UpdateOrCancelCard";
import { ADDRESS_FIELDS_INPUT_MAX_LENGTH, BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH, ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, refreshBtn, USER_PROFILE_EDIT_TYPE } from "../../../constants/AppConstants";
import Loader from "../../../components/shared/loader/Loader";
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";
import { getLocalSessionData, getUserSessionData } from "../../../utils/Authentication/handleUserSession";
import { routePath } from "../../../constants/Routes";
import MyDevice from "../../../components/DisplayMyAccount/MyDevice";
import customerUserPlaceholderIcon from "../../../assets/images/user-placeholder-icon.svg";
import { AZURE_ADB2C_TENANT_NAME, profileEditRequest, updateUserPasswordRequest, updateUserProfileRequest } from "../../../utils/Authentication/Azure/AuthConfig";
import { AzureADB2CSignInRedirect, MSALInstance } from "../../../utils/Authentication/Azure/MSALInstanceInit";
import { trimAddressFieldsLength } from "../../../constants/AppMethod";

const MyAccountLanding = () => {
  const history = useHistory();
  const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();
  const fetchedMyAccount = useSelector((state) => state.FetchMyAccount);
  const dashboardDeviceInfo = useSelector((state) => state.DashboardDeviceInfo);
  const planName = dashboardDeviceInfo.deviceInfo?.service?.currentPlan?.planName;
  const planPages = dashboardDeviceInfo.deviceInfo?.service?.currentPlan?.planPages;
  const isSwapEligible = dashboardDeviceInfo.deviceInfo?.isSwapEligible;
  const deviceModel = dashboardDeviceInfo.deviceInfo?.model;
  const deviceSerialNumber = dashboardDeviceInfo.deviceInfo?.serialNumber

  const moveToEditPage = (page, currentAccount) => {
    dispatch(ManageUpdateShippingResetState());
    dispatch(ManageSubscriptionResetState());
    dispatch(UpdatePlanReset())
    const isEditPaymentDetails = (currentAccount === "paymentDetails") ? true : false;
    history.push({ pathname: (isEditPaymentDetails) ? routePath.EDIT_PAYMENT : page, state: {
      'accountClicked':currentAccount, data: (isEditPaymentDetails) ?  fetchedMyAccount : null}
    });
  };
  const onTermsAndCondition = (url) => {
    window.open(url, "_blank")
  }
  /**
   * Method to obtain current user email id 
   * to be passed into edit profile and password request wihthout the need to reauthenticate again
   * @returns string | void
   */
  const obtainCurrentUserLoginHint = () => {
    let currentAuthAccount = MSALInstance.getAllAccounts();
    if(currentAuthAccount.length > 0){
      return currentAuthAccount[0].idTokenClaims["signInNames.emailAddress"];
    } else {
      console.error('Application Error: Authentication Invalid');
      AzureADB2CSignInRedirect();
    }
  }
  const onUpdateUserProfile = (type) => {
    let URL = `https://${AZURE_ADB2C_TENANT_NAME}.b2clogin.com/${AZURE_ADB2C_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/authorize`;
    let requestQuery;
    if(type === USER_PROFILE_EDIT_TYPE.password){
      requestQuery = {...updateUserPasswordRequest, ...profileEditRequest};
    } else {
      requestQuery = {...updateUserProfileRequest, ...profileEditRequest}
    }
    const loginHint = obtainCurrentUserLoginHint();
    requestQuery.loginHint = loginHint;
    const finalURL = buildQuery(URL, requestQuery);
    window.open(finalURL, "_self");
  }
  const buildQuery = (URL, queries) => {
    var query = [];
    for (var key in queries) {
        //encode the keys and values this is most necessary for search inputs 
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(queries[key]));
    }
    //construct new URL
    let new_url = URL + (query.length ? '?' + query.join('&') : '');
    return(new_url);
  }
  useEffect(() => {
    dispatch(FetchMyAccount(UID,deviceID));
    dispatch(FetchDeviceInfo(deviceID, UID));
    // eslint-disable-next-line
  }, [dispatch, UID, deviceID]);

  let shippingAddressI = fetchedMyAccount?.myAccount?.shippingAddress
  let billingAddressI = fetchedMyAccount?.myAccount?.billingAddress
  
  let shippingAddress = {
    address: trimAddressFieldsLength(shippingAddressI?.addressLine1, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
    addressLine2: trimAddressFieldsLength(shippingAddressI?.addressLine2, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
    city: shippingAddressI?.cityTown,
    country: shippingAddressI?.country,
    postcode: shippingAddressI?.postCode,
  };

  let billingAddress = {
    addressB: trimAddressFieldsLength(billingAddressI?.addressLine1, BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH),
    addressLine2B: trimAddressFieldsLength(billingAddressI?.addressLine2, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
    cityB: billingAddressI?.cityTown,
    countryB: billingAddressI?.country,
    postcodeB: billingAddressI?.postCode,
  };

  const { address, addressLine2, city, postcode } = shippingAddress;
  const { addressB, addressLine2B, cityB, postcodeB } = billingAddress;

  if(fetchedMyAccount.loading || dashboardDeviceInfo.loading) {
    return <Loader></Loader>
  }
  if(fetchedMyAccount.error) {
    return <ErrorPage errorInfo={fetchedMyAccount.error} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
  }
  return (
    <div>
      <div className="my-account-profile">
        <div className="profile-img">
          <img src={customerUserPlaceholderIcon} alt="user-profile"/>
        </div>
        {/*  @todo Will be enabled after integration
        <div className="f-14 fc-e profile-change">
          {t("DASHBOARD.USER_PROFILE.CHANGE")}
        </div> */}
      </div>
      <div className="container my-account-card mt-30">
        <PersonalDetails myAccountData={fetchedMyAccount}/>
      </div>
      <div className="container my-account-card mt-5">
        <UpdateOrCancelCard
        header={t("DASHBOARD.MY_ACCOUNT_LANDING.UPDATE_PERSONAL_DETAILS")}
        moveToEditPage={()=>onUpdateUserProfile(USER_PROFILE_EDIT_TYPE.profile)}/>
      </div>
      <div className="container my-account-card mt-5">
        <UpdateOrCancelCard
        header={t("DASHBOARD.MY_ACCOUNT_LANDING.CHANGE_PASSWORD")}
        moveToEditPage={()=>onUpdateUserProfile(USER_PROFILE_EDIT_TYPE.password)}/>
      </div>
      <div className="container my-account-card">
        <ShippingAndBillingAddress
          moveToEditPage={() =>
            moveToEditPage(routePath.EDIT_ACCOUNT, "shippingAddress")
          }
          header={t("DASHBOARD.ORDER_DETAILS.SHIPPING_ADDRESS")}
          address={address}
          addressLine2={addressLine2}
          city={city}
          postcode={postcode}
        />
      </div>
      <div className="container my-account-card mt-30">
        <ShippingAndBillingAddress
          moveToEditPage={() =>
            moveToEditPage(routePath.EDIT_ACCOUNT, "billingAddress")
          }
          header={t("DASHBOARD.ORDER_DETAILS.BILLING_ADDRESS")}
          address={addressB}
          addressLine2={addressLine2B}
          city={cityB}
          postcode={postcodeB}
        />
      </div>
      <div className="container my-account-card mt-30">
        <MyPlan moveToEditPage={moveToEditPage} paymentCardNumber={fetchedMyAccount?.myAccount?.userPaymentViewModel?.paymentDisplayText} planName={planName} planPages={planPages} />
      </div>
      <div className="container my-account-card mt-5">
        <UpdateOrCancelCard
        header={t("DASHBOARD.MY_ACCOUNT_LANDING.CANCEL_PLAN")}
        moveToEditPage={()=>moveToEditPage(routePath.UPDATE_ACCOUNT_CONFIRMATION, "cancelPlan")}
        />
      </div>
      <div className="container my-account-card mt-30">
        <MyDevice 
        deviceModel = {deviceModel}
        deviceSerialNumber = {deviceSerialNumber}
        />
      </div>
      {isSwapEligible?
      <div className="container my-account-card mt-5">
        <UpdateOrCancelCard
        header={t("DASHBOARD.DISPLAY_DEVICE.CHANGE_DEVICE")}
        moveToEditPage={()=>moveToEditPage(routePath.CHANGE_DEVICE, "changeDevice")}
        account="changeDevice"
        />
      </div>
      :
      null}
      <div className="container my-account-card mt-30 mb-60">
        <LegalDocuments onTermsAndCondition={onTermsAndCondition}/>
      </div>
    </div>
  );
};

export default MyAccountLanding;
