import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DisplayDate } from "../../../../../utils/DisplayDate";
import EcoProButton from "../../../../shared/button/button";
import EcoProOverlayModal from "../../../../shared/modal/OverlayModal/EcoProOverlayModal";
import Feedback from "../../../../../pages/Account/CancelPlan/Feedback/Feedback";
import { useSelector } from "react-redux";
import Loader from "../../../../shared/loader/Loader";
import { userRoles } from "../../../../../constants/UserRoles";
import { currentUserRolesArr } from "../../../../../constants/AppMethod";

const SubscriptionDetails = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const planName = props?.deviceInfo?.service?.currentPlan?.planName;
    const planPages = props?.deviceInfo?.service?.currentPlan?.planPages;
    const maskedCardNumber =`${props?.subscriptionAccountSummary?.basicInfo?.defaultPaymentMethod?.creditCardType}(${props?.subscriptionAccountSummary?.basicInfo?.defaultPaymentMethod?.creditCardNumber})`;
    const planEnrolledDate = props?.deviceInfo?.service?.enrolledDate;
    const preEnrollInfoData = props?.preEnrollmentInfo;
    const displayName =  props?.userAccount?.displayName;
    const emailAddress = props?.userAccount?.emailAddress;
    const deviceSerialNumber = props?.deviceInfo?.serialNumber;
    const deviceId = props?.deviceInfo?.deviceId;
    const countryCode = props?.userAccount?.countryCode;
    const userId = props?.userAccount?.userId;
    const cancelOrderResponse = useSelector((state) => state.CancelOrder);
    const [callCancelOrder, setcallCancelOrder] = useState(true);
    const currentUserRoles = currentUserRolesArr();
    const [modalState, setModalState] = useState(false);
    const [modalProps, setModalProps] = useState({
        modal_title: "",
        modal_sub_title: "",
        modal_content: "",
        cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
        confirm_btn_text: t("MODAL_BUTTON_TXT.CONFIRM"),
        toggleModal: "",
        customClassName: "",
        confirm_btn_onClick: "",
        type: "normal"
    })
    const customerInfo = {
        'deviceId': deviceId, 
        'customerCountryCode': countryCode,
        'customerUserId': userId,
        'confirmStatus': true
    }
    const constructUserInfoContent = (name, emailId, serialNumber) => {
        return <>
            <p>{t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.MODAL_CONTENT")}</p>
            <ul>
                <li><span>{t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.NAME")}:&nbsp;&nbsp;&nbsp;</span> <span>{name}</span></li>
                <li><span>{t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.EMAIL")}:&nbsp;&nbsp;&nbsp;</span> <span>{emailId}</span></li>
                <li><span>{t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.SERIAL_NUMBER")}:&nbsp;&nbsp;&nbsp;</span> <span>{serialNumber}</span></li>
            </ul>
        </>
    }
    const toggleModal = (event) => {
        setModalState(false);
    }
    const confirmedCancellation = () => {
        setModalState(true);
        setModalProps({
            modal_title: t("CONFIRM_CANCELLATION.CANCELLATION_FEEDBACK_MODAL_TITLE"),
            modal_content: <Feedback {...customerInfo}/>,
            toggleModal: toggleModal,
            customClassName: "subscription-cancellation-feedback",
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL")
        });
     }
    const handlePlanCancellationModal = () => {
        setModalState(true);
        setModalProps({
            modal_title: t("CONFIRM_CANCELLATION.CANCELLATION_CONFIRMATION_MODAL_CONTENT.MODAL_TITLE"),
            modal_content: constructUserInfoContent(displayName, emailAddress, deviceSerialNumber),
            toggleModal: toggleModal,
            customClassName: "subscription-cancellation-confirmation",
            confirm_btn_onClick: confirmedCancellation,
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
            confirm_btn_text:   t("MODAL_BUTTON_TXT.CONTINUE"),
        });
    }
    if((cancelOrderResponse.success || cancelOrderResponse.error) && callCancelOrder){
       setModalState(true);
        setModalProps({
            modal_title: (cancelOrderResponse.success) 
                            ? t('CONFIRM_CANCELLATION.CANCELLATION_COMPLETION_TITLE.SUCCESS') 
                            : t('CONFIRM_CANCELLATION.CANCELLATION_COMPLETION_TITLE.ERROR'),
            modal_content: <>
                                <p>
                                    {
                                        (cancelOrderResponse.success) 
                                        ? t("CONFIRM_CANCELLATION.CANCELLATION.SUCCESS") 
                                        : t("CONFIRM_CANCELLATION.CANCELLATION.ERROR")
                                    }
                                </p>
                                <p>{t('CONFIRM_CANCELLATION.DATA_REFRESHING_CONTENT')}</p>
                            </>,
            toggleModal: toggleModal,
            customClassName: "subscription-cancellation-final-status"
        });
        setcallCancelOrder(false);
        setTimeout(() => {
            window.location.reload();
        }, 6000);
    }
    return (
        <div className="overview-container subscription-details-wrapper mt-40">
              { cancelOrderResponse.loading && <Loader setOverlay={true}></Loader> }
            <div id="subscription-billing-details">
                <div className="header">
                    <div className="title">
                        <h1>{t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.SUBSCRIPTION_DETAILS')}</h1>
                    </div>
                    {/* <div className="last-updated-date-info">
                        <p>Last updated: 07/03/2022  - 17:19pm</p>
                    </div> */}
                </div>
                <div className="device-details-info mt-15">
                    { !preEnrollInfoData && <p>{t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.PLAN_TYPE')}: <span>{t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.CURRENT_PLAN_NAME', { plan_name: planName, plan_pages: planPages })}</span></p> }
                    <p>{t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.PAYMENT_METHOD')}: <span>{maskedCardNumber}</span></p>
                    { !preEnrollInfoData && <p>{t('CUSTOMER_DASHBOARD.SUBSCRIPTION_BILLING_DETAILS.DATE_STARTED')}: <span>{DisplayDate(planEnrolledDate, { year: 'numeric', month: '2-digit', day: '2-digit' })}</span></p> }
                </div>
            </div>
           {   
                (
                    props?.enrollmentStatus?.toUpperCase() === 'ACTIVE' 
                    && currentUserRoles.indexOf(userRoles.CSAGENT_ADMIN) > -1
                ) 
                && <div id="subscription-cancellation-wrapper">
                    <div className="header">
                        <div className="title">
                            <h1>{t('CUSTOMER_DASHBOARD.SUBSCRIPTION_CANCELLATION.header')}</h1>
                        </div>
                    </div>
                    <div className="cancellation-btn">
                        <EcoProButton cname="default" btnID="csagent-subscription-cancellation-btn" onBtnClick={(event) => handlePlanCancellationModal(event)}>{t('MODAL_BUTTON_TXT.CANCEL')}</EcoProButton>
                    </div>
                </div> 
            }
            <EcoProOverlayModal 
                    {...modalProps} 
                    modal_state={modalState}
            ></EcoProOverlayModal>
        </div>
        
    )
}

export default SubscriptionDetails;