export const CHANGE_ACCOUNT_STATUS = 'CHANGE_ACCOUNT_STATUS'

export const ACCOUNT_ZBILLING_INFO_RENDERING_INIT = 'ACCOUNT_ZBILLING_INFO_RENDERING_INIT';
export const ACCOUNT_ZBILLING_INFO_RENDERING_SUCCESS = 'ACCOUNT_ZBILLING_INFO_RENDERING_SUCCESS';
export const ACCOUNT_ZBILLING_INFO_RENDERING_ERROR = 'ACCOUNT_ZBILLING_INFO_RENDERING_ERROR';

export const ACCOUNT_MANAGE_SUBSCRIPTION_INIT = 'ACCOUNT_MANAGE_SUBSCRIPTION_INIT';
export const ACCOUNT_MANAGE_SUBSCRIPTION_SUCCESS = 'ACCOUNT_MANAGE_SUBSCRIPTION_SUCCESS';
export const ACCOUNT_MANAGE_SUBSCRIPTION_ERROR = 'ACCOUNT_MANAGE_SUBSCRIPTION_ERROR';

export const ACCOUNT_ENROLLMENT_INIT = 'ACCOUNT_ENROLLMENT_INIT';
export const ACCOUNT_ENROLLMENT_SUCCESS = 'ACCOUNT_ENROLLMENT_SUCCESS';
export const ACCOUNT_ENROLLMENT_ERROR = 'ACCOUNT_ENROLLMENT_ERROR';

export const SHOW_ZIPCODE_ERROR = 'SHOW_ZIPCODE_ERROR'
export const SHOW_MOBILE_ERROR = 'SHOW_MOBILE_ERROR'
export const SHOW_EMAIL_ERROR = 'SHOW_EMAIL_ERROR'

export const FETCH_PAYMENT_OPTIONS_INIT = 'FETCH_PAYMENT_OPTIONS_INIT';
export const FETCH_PAYMENT_OPTIONS_SUCCESS = 'FETCH_PAYMENT_OPTIONS_SUCCESS';
export const FETCH_PAYMENT_OPTIONS_ERROR = 'FETCH_PAYMENT_OPTIONS_ERROR';
export const SET_PAYMENT_OPTIONS_INIT = 'SET_PAYMENT_OPTIONS_INIT';

export const RESET_STATE = 'RESET_STATE';
export const ZUORA_PAYMENT_INFO_RENDERING_RESET_STATE = 'ZUORA_PAYMENT_INFO_RENDERING_RESET_STATE';
export const MANAGE_SUBSCRIPTION_RESET_STATE = 'MANAGE_SUBSCRIPTION_RESET_STATE';

export const UPDATE_USER_PROFILE_INIT = 'UPDATE_USER_PROFILE_INIT';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';

