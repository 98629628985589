/**
 * This file serves translated plan names.
 * 
 * Though the web signup app is served based on browser setting languages
 * the plan name will be served based on the domain language.
 */
import axios from "axios";

const TranslatedPlanNames = async(langcode, dataToBeTranslated) => {
    const response = await axios.get(`./locales/${langcode}/translation.json`);
    const translatedPlanName = response.data.ECOPROCAROUSEL_CONTENT[dataToBeTranslated];
    return translatedPlanName;
 }
 
 export default TranslatedPlanNames;