import React from "react";
import { Modal } from "reactstrap";
import EcoProButton from "../../button/button";
import './EcoProOverlayModal.css';
import decrementIcon from '../../../../assets/images/cs-agent/decrement.svg';
import incrementIcon from '../../../../assets/images/cs-agent/increment.svg';

const EcoProOverlayModal = (props) => {
    const {
        modal_type,
        modal_state,
        modal_title,
        modal_sub_title,
        modal_content,
        cancel_btn_text,
        confirm_btn_text,
        toggleModal,
        customClassName,
        confirm_btn_onClick,
        type,
        modal_table_title,
        modal_table_quantity,
        incrementCounter,
        decrementCounter,
        counter,
        modal_size
    } = props;
    const classNames = (customClassName) ? customClassName.split(',').join('') : '';
    const formatInputName = (dataStr) => {
        return dataStr.split(" ").join("_").toLowerCase();
    }
     return (
        <Modal isOpen={modal_state} toggle={() => toggleModal()} className={`cs-agent-modal-overview ${classNames}`} size={modal_size}>
            <div className="ecopro-modal-header">
                <h5>{modal_title}</h5>
                <p>{modal_sub_title}</p>
            </div>
            <div className="quantity-content-wrapper quantity-table-wrapper">
                <div className="list-name">{modal_table_title}</div>
                <div className="list-value-wrapper quantity">
                {modal_table_quantity}
                </div>
            </div>
            <div className="ecopro-modal-content f-16">
                {
                    type === 'quantity' ? (
                            Object.values(modal_content).map((data, index) => (
                                <div key={index} className="quantity-content-wrapper">
                                    <div className="list-name">{data.content}</div>
                                    <div className="list-value-wrapper">
                                        <img className="inc-dec-icon"  name={formatInputName(data.key)} src={decrementIcon} alt="decrement" onClick={(event) => decrementCounter(event)}/>
                                        <input type="text" name={formatInputName(data.key)} value={counter[formatInputName(data.key)]?counter[formatInputName(data.key)]:'0'} readOnly></input>
                                        <img className="inc-dec-icon"  name={formatInputName(data.key)} src={incrementIcon} alt="increment" onClick={(event) => incrementCounter(event)}/>
                                    </div>
                                </div>
                            ))
                    ) : (
                        <p>{modal_content}</p>
                    )
                }
               
            </div>
            <div className="ecopro-modal-footer">
                {cancel_btn_text && 
                <EcoProButton cname="default white-bg-btn" onBtnClick={() => toggleModal()}>{cancel_btn_text}</EcoProButton>}
                {confirm_btn_text && 
                <EcoProButton cname="default" onBtnClick={() => confirm_btn_onClick(modal_type,counter)}>{confirm_btn_text}</EcoProButton>}
            </div>
        </Modal>
    )
}

export default EcoProOverlayModal;