import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLangCode } from "../../../../utils/Internationalization/handleAppInternationalization";
import ErrorPage from "../../../../components/shared/errorPage/ErrorPage";
import { CancelOrder, storeSelectedFeedback } from "../../../../actions/Dashboard";
import Loader from "../../../../components/shared/loader/Loader";
import { routePath } from "../../../../constants/Routes";
import PlanCancellationFeedback from "../../../../components/PlanCancellationFeedback/PlanCancellationFeedback";
import { FALLBACK_LNG, blockSpecialCharacterRegex } from "../../../../constants/AppConstants";
import { userRoles } from "../../../../constants/UserRoles";
import { currentUserRolesArr, validateRoleAndPathname_CSAGENT_ADMIN } from "../../../../constants/AppMethod";


const Feedback = (props) => {
  const history = useHistory();
   const dispatch = useDispatch();
  const Roles = currentUserRolesArr();
  const translationNamespace = (validateRoleAndPathname_CSAGENT_ADMIN(Roles, window.location.pathname)) ? 'CSAgenttranslation' :'translation';
  const { t } = useTranslation([translationNamespace]);
  const countryCode = (Roles.indexOf(userRoles.CSAGENT_ADMIN) > -1) ? 'en-GB' : getLangCode();
  const [selectedFeedback, setSelectedFeedback] = useState([]);
  const [otherReason, setOtherReason] = useState("");
  const cancelOrderResponse = useSelector((state) => state.CancelOrder);
  const [content, setContent] = useState();
  //The below props are direct ones from CSAgent cancel subscription module
  const { deviceId, customerCountryCode, confirmStatus, customerUserId } = props;
  //The below props are passed as state from history object from customer subscription cancellation module
  const { customerConfirmStatus } = props?.location?.state || {};
  const selectFeedback = (feedbackKey) => {
    if (selectedFeedback.includes(feedbackKey)) {
      let filteredFeedback = selectedFeedback.filter(
        (key) => key !== feedbackKey
      );
      setSelectedFeedback(filteredFeedback);
    } else {
      setSelectedFeedback([...selectedFeedback, feedbackKey]);
    }
  };
  const handleFallBackData = () => {
    const fallbackLanguage = FALLBACK_LNG
    import(`../../../../contents/${fallbackLanguage}/PlanCancellationFeedback.json`)
    .then((data) => {
      setContent(data.feedbackList);
      setSelectedFeedback(cancelOrderResponse.selectedFeedback.reasonCodes);
      setOtherReason(cancelOrderResponse.selectedFeedback.otherReason);
    })
    .catch((error) => {
      throw error
    })
  }
  
  useEffect(() => {
    new Promise((res, rej) => {
      import(
        `../../../../contents/${countryCode}/PlanCancellationFeedback.json`
      )
        .then((data) => {
          if(data.feedbackList){
          setContent(data.feedbackList);
          setSelectedFeedback(cancelOrderResponse.selectedFeedback.reasonCodes);
          setOtherReason(cancelOrderResponse.selectedFeedback.otherReason);
          }
          else{
            handleFallBackData()
          }
        })
        .catch((error) => {
          handleFallBackData()
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  if (!content) {
    return <Loader></Loader>;
  }

  
  const confirmCancellation = () => {
    if(validateRoleAndPathname_CSAGENT_ADMIN(Roles, window.location.pathname)){
      const currentUserRole = userRoles.CSAGENT_ADMIN;
      const  reasonCodes = selectedFeedback;
      const otherReasonData = selectedFeedback.includes("other") ? otherReason : "";
      dispatch(CancelOrder(customerUserId, deviceId, reasonCodes, otherReasonData, currentUserRole, customerCountryCode));
    } else {
      dispatch(
        storeSelectedFeedback({
          reasonCodes: selectedFeedback,
          otherReason: selectedFeedback.includes("other") ? otherReason : "",
        })
      );
      history.push({
        pathname: routePath.CANCEL_PLAN_CONFIRM,
        state: {
          confirmStatus: true,
        },
      });
    }
  };
  const handleOtherReason = (event) => {
    const {value} = event.target

    if (value === "" || blockSpecialCharacterRegex.test(value)) {
      setOtherReason(value);
    }
    
  };
  return (confirmStatus === true || customerConfirmStatus) ? (
    <div>
      <PlanCancellationFeedback
        otherReason={otherReason}
        handleOtherReason={handleOtherReason}
        selectFeedback={selectFeedback}
        confirmCancellation={confirmCancellation}
        content={content}
        selectedFeedback={selectedFeedback}
        userRole={Roles}
      />
    </div>
  ) : (
    <ErrorPage errorInfo={t("ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED")} />
  );
};

export default Feedback;
