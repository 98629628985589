import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { FetchUserSubscriptionInfoInit } from "../../../../../actions/Web/Customer/SignUp";
import { FetchUserAccount } from "../../../../../actions/UserAccount";
import ErrorPage from "../../../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../../../components/shared/loader/Loader";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../../../../constants/AppConstants";
import { WebCustomerRoutePaths, routePath } from "../../../../../constants/Routes";
import { getLocalSessionData, getUserSessionData, setLocalSessionData } from "../../../../../utils/Authentication/handleUserSession";
import { GetPreEnrollmentInfo } from "../../../../../actions/Web/Customer/SignIn";

const ProcessUserAccount = (props) => {
    const BOCUserId  = props.location?.state?.BOCUserId;
    const dispatch = useDispatch();
    const [userSubscriptionInfoFlag, setUserSubscriptionInfoFlag] = useState(true);
    const [userAccountFlag, setUserAccountFlag] = useState(true);
    const UserAccountResult = useSelector((state) => state.UserAccount);
    const UserSubscriptionInfo = useSelector((state) => state.UserSubscriptionInfo);
    const GetPreEnrollmentInfoResp = useSelector((state) => state.GetPreEnrollmentInfo);
    const AZURE_AD_B2C_UID = getLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID);
    const SignupType = getLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE);
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
   
    useEffect(() => {
        setLocalSessionData(LOCALSESSIONDATAKEYS.UID, BOCUserId);
        dispatch(FetchUserSubscriptionInfoInit(BOCUserId));
    }, [dispatch, AZURE_AD_B2C_UID, BOCUserId, SignupType]);
    
     if(GetPreEnrollmentInfoResp.error){
        return <Redirect to={WebCustomerRoutePaths.ACCOUNT} />
    }
    if(GetPreEnrollmentInfoResp.success && GetPreEnrollmentInfoResp.data.id){   
        return <Redirect to={routePath.CONNECT_DEVICE_DASHBOARD} />
    }
    if(UserAccountResult.error){
        return <ErrorPage errorInfo={UserAccountResult.error}></ErrorPage>
    }
    if(UserAccountResult.success && userAccountFlag){
        /**
         * Action to check whether the customer has completed Prenrollment
         * If Preenrollment completed, customer redirected to web signup dashbaord page
         * If Preenrollment not completed, customer redirected to Account page.
         */
         dispatch(GetPreEnrollmentInfo(UID));
         setUserAccountFlag(false);
    }
    if(UserSubscriptionInfo.error){
       return <ErrorPage errorInfo={UserSubscriptionInfo.error}></ErrorPage>
    }
    if (UserSubscriptionInfo.success === true && userSubscriptionInfoFlag) {
        dispatch(FetchUserAccount(AZURE_AD_B2C_UID, BOCUserId, null, SignupType));
        setUserSubscriptionInfoFlag(false);
    } 
    return (
        <Loader></Loader>
    )
}

export default ProcessUserAccount;