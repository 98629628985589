import React from "react";
import banner_EN from '../../../assets/images/easySetup/banner/banner_GB.png';
import banner_DE from '../../../assets/images/easySetup/banner/banner_DE.png';
import banner_FR from '../../../assets/images/easySetup/banner/banner_FR.png';
import banner_IT from '../../../assets/images/easySetup/banner/banner_IT.png';
import banner_NL from '../../../assets/images/easySetup/banner/banner_NL.png';
import './EasySetupBanner.css'
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../utils/CustomHooks/UseQuery";

const EasySetupBanner = () => {
  const {t} = useTranslation(["translation"])
  const queryParams = useQuery();
  const languageCode = queryParams.get("language");

  const imageUrlCountry = {
    "EN":banner_EN,
    "DE":banner_DE,
    "FR":banner_FR,
    "IT":banner_IT,
    "NL":banner_NL
  }

  const getImagesOnCountryCode = () => {
    if(languageCode){
    return imageUrlCountry[languageCode.toUpperCase()]
    }
  }
  return (
    <div className="easy-setup-banner-container">
      <div className="column-a">
        <img src={getImagesOnCountryCode()} alt="banner" />
      </div>
      <div className="column-b">
        <h1>{t("EASY_SETUP.BANNER.HEADER")}</h1>
        <p>{t("EASY_SETUP.BANNER.LINE1")}</p>
        <p>{t("EASY_SETUP.BANNER.LINE2")}</p>
        <p>{t("EASY_SETUP.BANNER.LINE3")}</p>
        <p>{t("EASY_SETUP.BANNER.LINE4")}</p>
        <div className="learn-more-link">
          <a href="/#" target = "_blank"  rel="noreferrer">{t("EASY_SETUP.BANNER.LEARN_MORE")}</a>
        </div>
      </div>
    </div>
  );
};

export default EasySetupBanner;
