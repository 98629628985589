import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { userRoles } from "../constants/UserRoles";
import { routePath } from "../constants/Routes";
import AdminLayout from "../Layouts/Admin/AdminLayout";
import { currentUserRolesArr } from "../constants/AppMethod";

const ADB2CAdminProtectedRoute = ({ component: Component, ...rest }) => {
    const { instance } = useMsal();
    const accounts = instance.getAllAccounts();
    const isAuthenticated = (accounts.length > 0) ? true : false;
    const currentUserRoles = currentUserRolesArr();
    const USERROLE_ALLOWED = userRoles.ADMIN;

    return (
        <Route {...rest} render={props => {
            if (!isAuthenticated) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: routePath.SHENLONG_REDIRECT, state: { from: props.location } }} />
            }
            if(!currentUserRoles || currentUserRoles.indexOf(USERROLE_ALLOWED) === -1){
                return <Redirect to={{ pathname: routePath.SHENLONG_REDIRECT, state: { from: props.location } }} />
            }
            // authorised so return component
            return (
                <AdminLayout>
                    <Component {...props} />
                </AdminLayout>
            )
        }} />
    )
}

export default ADB2CAdminProtectedRoute;