import React, { useEffect, useState } from "react";
import './FAQ.css';
import { Accordion } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { getLangCode } from "../../../utils/Internationalization/handleAppInternationalization";
import convertHtmlToReact from "@hedgedoc/html-to-react";
import EcoProButton from "../../shared/button/button";
import { FALLBACK_LNG, LOCALSESSIONDATAKEYS, SIGNUPTYPE } from "../../../constants/AppConstants";
import { useTranslation } from "react-i18next";

const FAQPlansPage = (props) => {
  const { flowType } = props;
    const [FAQContent, setFAQContent] = useState([]);
    const {t} = useTranslation(["translation"])
    useEffect(() => {
        fetchFAQData();
        // eslint-disable-next-line
    }, []);

    const scrollToPlansSection = () => {
      const element = document.getElementById("plans-slider-section");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    const fetchFAQData = () => {
      let languageCode;
      if(flowType === SIGNUPTYPE.MOBILE){
        languageCode = getLangCode()
      }
      else{
        languageCode = localStorage.getItem(LOCALSESSIONDATAKEYS.ECOPROWEBSIGNUPLANGCODE)
      }
       
        new Promise((res, rej) => {
            import(
              `../../../contents/${languageCode}/PlansPageFAQ.json`
            )
              .then((data) => {
                if(data.FAQ){
                    setFAQContent(data.FAQ)
                }
                else{
                  handleFAQFallBackData()
                }
                
              })
              .catch((error) => {
                handleFAQFallBackData()
              });
          });
    }
    const handleFAQFallBackData = () => {
      const fallbackLanguage = FALLBACK_LNG
      import(`../../../contents/${fallbackLanguage}/PlansPageFAQ.json`)
      .then((data) => {
        if(data.FAQ){
            setFAQContent(data.FAQ);
        }
      })
      .catch((error) => {
        throw error
      }) 
    }
    return (
        <div className="FAQ-section-component-wrapper container">
            <h5 className="section-title">{t("LANDING_PAGE.FREQUENTLY_ASKED_QUESTIONS")}</h5>
            <div className="FAQ-accordion-container">
                <Accordion flush>
                {FAQContent.length > 0 && FAQContent?.map((data,i) => (
                    <AccordionItem eventKey={i}>
                    <AccordionHeader>{data.title}</AccordionHeader>
                    <AccordionBody>
                      <p>{convertHtmlToReact(data.content, [true])}</p>
                    </AccordionBody>
                  </AccordionItem>
                  ))}
                </Accordion>
            </div>
            <div className="button-container mt-40">
                <EcoProButton cname="default" onBtnClick= {() => scrollToPlansSection()}>{t("BENEFITS_OF_SUPPLIES.VIEW_PLANS")}</EcoProButton>
            </div>
        </div>
    )
}

export default FAQPlansPage;