import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import { EnableDeviceSwap, ResetOrderConsumable, UpdateOrderConsumables } from "../../../../../actions/CSAgentPortal/CustomerDashboard";
import EcoProButton from "../../../../shared/button/button";
import EcoProOverlayModal from "../../../../shared/modal/OverlayModal/EcoProOverlayModal";
import './Overview.css';
import Loader from "../../../../shared/loader/Loader";
import { alertSubscriptionId, ORDER_PARTS_CONSUMABLES, ORDER_SUPPLIES_CONSUMABLES } from "../../../../../constants/AppConstants";
import EcoproAlert from "../../../../shared/alert/alert";
import AlertDanger from '../../../../../assets/images/alert-danger.svg'
import { DisplayDate } from "../../../../../utils/DisplayDate";
import { printerTypes } from "../../../../../constants/PrinterTypes";

const Overview = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const dispatch = useDispatch();
    const [modalState, setModalState] = useState(false);
    const [modalProps, setModalProps] = useState({
        modal_title: "",
        modal_sub_title: "",
        modal_content: "",
        cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
        confirm_btn_text: t("MODAL_BUTTON_TXT.CONFIRM"),
        toggleModal: "",
        customClassName: "",
        confirm_btn_onClick: "",
        type: "normal"
    })
    const [orderSuppliesState, setOrderSuppliesState] = useState(null);
    const [swapDeviceState, setSwapDeviceState] = useState(null);
    const [isOrderAlertOpen, setOrderAlertOpen] = useState(true);
    const [isDeviceAlertOpen, setDeviceAlertOpen] = useState(true);
    const [isSubscriptionAlertOpen, setSubscriptionAlertOpen] = useState(true);
    const [counter, setCounter] = useState({})
    const swapDeviceRes = useSelector(state => state.CSAGENT.EnableDeviceSwap);
    const orderConsumablesRes = useSelector(state => state.CSAGENT.UpdateOrderConsumables)
    const dashboardInfoRes = useSelector(state => state.CSAGENT.CustomerDashboardInfo);
    const planPages = (props?.deviceInfo?.service?.currentPlan?.planPages) ? props?.deviceInfo?.service?.currentPlan?.planPages : 0;
    const planPagesLeft = (props?.deviceInfo?.service?.currentUsage?.printedPlanPages) ? props?.deviceInfo?.service?.currentUsage?.printedPlanPages : 0;
    const rollOverPages = (props?.deviceInfo?.service?.currentUsage?.givenRolloverPages) ? props?.deviceInfo?.service?.currentUsage?.givenRolloverPages : 0;
    const printedRolloverPages = (props?.deviceInfo?.service?.currentUsage?.printedRolloverPages) ? props?.deviceInfo?.service?.currentUsage?.printedRolloverPages : 0;
    const overagePages = (props?.deviceInfo?.service?.currentUsage?.purchasedOveragePages) ? props?.deviceInfo?.service?.currentUsage?.purchasedOveragePages : 0;
    const printedOveragePages =( props?.deviceInfo?.service?.currentUsage?.printedOveragePages) ?  props?.deviceInfo?.service?.currentUsage?.printedOveragePages : 0;
    const deviceModel = props?.deviceInfo?.model;
    const deviceSerialNumber =  props?.deviceInfo?.serialNumber;
    const printerType = props?.deviceInfo?.printerType
    // const planEnrolledDate = props?.deviceInfo?.service?.enrolledDate;
    const customerId = props?.userAccount?.userId;
    const customerDeviceId = props?.userAccount?.deviceId;
    const isSwapEligible = props?.deviceInfo?.isSwapEligible;
    const cancelSwapBtnStatus =  swapDeviceRes?.device_swap_status;
    const deviceAlertStatus = dashboardInfoRes?.customer_data?.deviceInfo?.service?.deviceAlert
    const subscriptionAlertStatus = dashboardInfoRes?.customer_data?.deviceInfo?.service?.subscriptionAlert
    const CustomerSubscriptionStatus = dashboardInfoRes?.customer_data?.subscriptionStatus?.toUpperCase();
    const WEB_SIGNUP_PreEnrollment_Info =  dashboardInfoRes?.customer_data?.preEnrollmentInfo;
    const ACTIVE = 'ACTIVE';
    const INACTIVE = 'INACTIVE';
    
    const scrollToTop = () => {
        window.scrollTo(0,0);
    }
    const toggleModal = (event) => {
        setCounter({})
        setModalState(modalState);
    }
    const swapDevice = (type) => {
        scrollToTop();
        setModalState(false);
        let swap_status = type === "swap" ? true : false;
        setSwapDeviceState(true);
        dispatch(EnableDeviceSwap(customerId, customerDeviceId, swap_status, isSwapEligible));
    }

    const handleSwapDevice = (type) => {
        setModalState(true);
        dispatch(ResetOrderConsumable()); 
        setOrderAlertOpen(true);
        let modal_title = type === "swap" ? t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_MODAL_TITLE") : t("CUSTOMER_DASHBOARD.OVERVIEW.CANCEL_SWAP_MODAL_TITLE")
        let modal_content = type === "swap" ? t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_MODAL_CONTENT") : t("CUSTOMER_DASHBOARD.OVERVIEW.CANCEL_SWAP_MODAL_CONTENT")
        setModalProps({
            modal_state: modalState,
            modal_title: modal_title,
            modal_content: modal_content,
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
            confirm_btn_text:  t("MODAL_BUTTON_TXT.CONFIRM"),
            toggleModal: toggleModal,
            customClassName: "customer-dashboard-swap-modal",
            confirm_btn_onClick: ()=>swapDevice(type),
            type:"normal"
        })
    }
    const incrementCounter = (e) => {
        const valuecounter = (counter[e.target.name]) ? counter[e.target.name] + 1 : 0+1;
        setCounter({...counter, [e.target.name]: valuecounter}) ;
    }
    const decrementCounter = (e) => {
        const valuecounter = (counter[e.target.name]) ? counter[e.target.name] - 1 : 0 ;
        setCounter({...counter, [e.target.name]: valuecounter}) ;
    }
    const handleOrderParts = () => {
        setModalState(true);
        dispatch(ResetOrderConsumable()); 
        setOrderAlertOpen(true);
        setModalProps({
            modal_type:"order_parts",
            modal_title: `${t("CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.ORDER_PARTS")} - ${deviceModel}`,
            modal_sub_title: t("CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.ORDER_PARTS_SUBITITLE"),
            modal_content: ORDER_PARTS_CONSUMABLES,
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
            confirm_btn_text:  t("CUSTOMER_DASHBOARD.OVERVIEW.CONFIRM_ORDER"),
            toggleModal: toggleModal,
            customClassName: "customer-dashboard-order-parts-modal",
            confirm_btn_onClick: triggerOrderPartsApiCall,
            type: "quantity",
            modal_table_title: t("CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.PART_NAME"),
            modal_table_quantity: t("CUSTOMER_DASHBOARD.OVERVIEW.QUANTITY")
        });
    }
    const handleOrderSupplies = () => {
        setModalState(true);
        dispatch(ResetOrderConsumable()); 
        setOrderAlertOpen(true);
        setModalProps({
            modal_type:"order_supplies",
            modal_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.ORDER_SUPPLIES"),
            modal_sub_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.SELECT_SUBTITLE"),
            modal_content: ORDER_SUPPLIES_CONSUMABLES,
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
            confirm_btn_text:   t("CUSTOMER_DASHBOARD.OVERVIEW.CONFIRM_ORDER"),
            toggleModal: toggleModal,
            customClassName: "customer-dashboard-order-parts-modal",
            confirm_btn_onClick: triggerOrderPartsApiCall,
            type: "quantity",
            modal_table_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.SUPPLY_TYPE"),
            modal_table_quantity: t("CUSTOMER_DASHBOARD.OVERVIEW.QUANTITY")
        });
    }
    const triggerOrderPartsApiCall = (type,data) => {
        scrollToTop();
        setModalState(false);
        let consumablePayload = []
        Object.keys(data).map(key => {
            for(let i = 1;i<=data[key];i++)
            consumablePayload.push(key)
            return data
        })
        setOrderSuppliesState(true)
        setCounter({})
        dispatch(UpdateOrderConsumables(customerId, customerDeviceId,consumablePayload))
    }

    const modalClose = () => {
        setModalState(false)
    }
    if(props?.cancelOrderResponse?.success){
        setModalState(true);
        setModalProps({
            modal_title: t("CONFIRM_CANCELLATION.CANCELLATION_SUCCESS_MODAL_TITLE"),
            modal_content:  t("CONFIRM_CANCELLATION.CANCELLATION_SUCCESS_MODAL_CONTENT"),
            confirm_btn_text:  t("MODAL_BUTTON_TXT.CLOSE"),
            customClassName: "customer-dashboard-swap-modal",
            confirm_btn_onClick: modalClose,
            type: "normal",
        });
    }
    if(swapDeviceRes?.success && swapDeviceState){
        const content = (swapDeviceRes.swap_status_requested) ?  t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_DEVICE.ENABLE_SWAP_CONFIRMED_CONTENT") : t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_DEVICE.DISABLE_SWAP_CONFIRMED_CONTENT");
        setModalState(true);
        setModalProps({
            modal_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SWAP_DEVICE.REQUEST_CONFIRMED"),
            modal_content: content,
            confirm_btn_text:  t("MODAL_BUTTON_TXT.CLOSE"),
            customClassName: "customer-dashboard-swap-modal",
            confirm_btn_onClick: modalClose,
            type: "normal",
        });
        setSwapDeviceState(false);
    }
    if(orderConsumablesRes?.success && orderSuppliesState){
        setModalState(true);
        setModalProps({
            modal_title: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.ORDER_CONFIRMED"),
            modal_content: t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.ORDER_CONFIRMED_CONTENT"),
            confirm_btn_text:  t("MODAL_BUTTON_TXT.CLOSE"),
            customClassName: "customer-dashboard-swap-modal",
            confirm_btn_onClick: modalClose,
            type: "normal",
        });
        setOrderSuppliesState(false)
    }
    const dismissOrderAlert = () => {
        setOrderAlertOpen(false);
    }

    const dismissDeviceAlert = () => {
        setDeviceAlertOpen(false)
    }

    const dismissSubscriptionAlert = () => {
        setSubscriptionAlertOpen(false)
    }
    return (
        <div className="overview-container mt-40">
            {
                (orderConsumablesRes.loading || swapDeviceRes.loading || props?.cancelOrderResponse?.loading) && <Loader setOverlay={true}></Loader>

            }
            {
                (props?.cancelOrderResponse?.error) && 
                    <EcoproAlert
                    alertImg = {AlertDanger} 
                    dismissAlert={dismissOrderAlert} 
                    alertSubHeader={props.cancelOrderResponse.error}
                    ></EcoproAlert>
            }
            { 
                (orderConsumablesRes.error || swapDeviceRes.error) && isOrderAlertOpen &&
                <EcoproAlert
                    alertImg = {AlertDanger} 
                    dismissAlert={dismissOrderAlert} 
                    alertSubHeader={t('CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')}
                ></EcoproAlert>
            }
            {
                deviceAlertStatus && deviceAlertStatus.some(data=>{return data.descriptionId === alertSubscriptionId.DEVICE_OFFLINE}) && isDeviceAlertOpen &&
                <EcoproAlert 
                    alertImg = {AlertDanger}
                    dismissAlert={dismissDeviceAlert}
                    alertHeader={t('CS_AGENT_ERROR_CONTENT.ERR_INFO.DEVICE_ALERT_HEADER')}
                    alertSubHeader={t('CS_AGENT_ERROR_CONTENT.ERR_INFO.DEVICE_ALERT_SUBHEADER',{model_number:deviceModel})}
                    ></EcoproAlert>
            }
            {
                subscriptionAlertStatus && subscriptionAlertStatus.some(data=>{return data.descriptionId === alertSubscriptionId.PAYMENT_FAILED}) && isSubscriptionAlertOpen &&
                <EcoproAlert 
                    alertImg = {AlertDanger}
                    dismissAlert={dismissSubscriptionAlert}
                    alertHeader={t('CS_AGENT_ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_ALERT_HEADER')}
                    alertSubHeader={t('CS_AGENT_ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_ALERT_SUBHEADER')}
                    // @todo will be enabled once feature is implemented
                    // alertBtnText={t('MODAL_BUTTON_TXT.VIEW_DETAILS')}
                    // handleAlertEvent={handlePaymentFailure}
                ></EcoproAlert>
               
            }
            {
                CustomerSubscriptionStatus && CustomerSubscriptionStatus.indexOf(INACTIVE) > -1 &&
                <EcoproAlert 
                alertClass = "dashboard-info"
                alertSubHeader={t('CUSTOMER_DASHBOARD.OVERVIEW.INACTIVE_INFO', {serial_number: CustomerSubscriptionStatus?.split(":")[1].replace(")", "").trim()})}
                showAlertDismiss = {false}
                ></EcoproAlert>

            }
            {
                WEB_SIGNUP_PreEnrollment_Info && <div>
                    <div id="pre-enrollment-info-details">
                        <div className="header">
                            <div className="title">
                                <h1>{t('CUSTOMER_DASHBOARD.PRE_ENROLL_DETAILS.PRE_ENROLL_INFO')}</h1>
                            </div>
                        </div>
                        <div className="device-details-info mt-15">
                            <p>{t('CUSTOMER_DASHBOARD.PRE_ENROLL_DETAILS.PRE_ENROLLMENT_ID')}: <span>{WEB_SIGNUP_PreEnrollment_Info?.id}</span></p>
                            <p>{t('CUSTOMER_DASHBOARD.PRE_ENROLL_DETAILS.PRE_ENROLL_DATE')}: <span>{DisplayDate(WEB_SIGNUP_PreEnrollment_Info?.date, {})}</span></p>
                        </div>
                    </div>
                </div>
            }
            { !WEB_SIGNUP_PreEnrollment_Info && <div>
                <div className="header">
                    <div className="title">
                        <h1>{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.TITLE')}</h1>
                    </div>
                    {/* 
                        @todo will be enabled once feature is implemented
                        <div className="last-updated-date-info">
                            <p>Last updated: 07/03/2022  - 17:19pm</p>
                        </div> 
                    */}
                </div>
                <Row className="device-page-info-wrapper">
                    <Col className="blocks move-left">
                        <div className="block-title">
                            <h5>{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.PAGES_USED')}</h5>
                        </div>
                        <div className="block-content">
                            <p><span>{planPagesLeft}</span> /{planPages}</p>
                            {
                                /* @todo will be enabled once feature is implemented 
                                <EcoProButton cname="default">{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.EDIT_PAGE_AMOUNT')}</EcoProButton> 
                                */
                            }
                        </div>
                    </Col>
                    <Col className="blocks move-left">
                        <div className="block-title">
                            <h5>{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.ROLLOVER')}</h5>
                        </div>
                        <div className="block-content">
                            <p><span>{printedRolloverPages}</span> /{rollOverPages}</p>
                            {
                                /* @todo will be enabled once feature is implemented 
                                <EcoProButton cname="default">{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.EDIT_ROLLOVER')}</EcoProButton> 
                                */
                            }
                        </div>
                    </Col>
                    <Col className="blocks move-left">
                        <div className="block-title">
                            <h5>{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.OVERAGES')}</h5>
                        </div>
                        <div className="block-content">
                            <p><span>{printedOveragePages}</span> /{overagePages}</p>
                            {
                                /* @todo will be enabled once feature is implemented 
                                <EcoProButton cname="default">{t('CUSTOMER_DASHBOARD.OVERVIEW.PLAN_INFORMATION.EDIT_OVERAGES')}</EcoProButton> 
                                */
                            }
                        </div>
                    </Col>
                </Row>
                <div className="device-details-wrapper mt-40">
                    <div className="header">
                        <div className="title">
                            <h1>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.TITLE')}</h1>
                        </div>
                        {/* 
                            @todo will be enabled once feature is implemented
                            <div className="last-updated-date-info">
                                <p>Last updated: 07/03/2022  - 17:19pm</p>
                            </div> 
                        */}
                    </div>
                    <div className="device-details-info mt-15">
                        <p>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.MODEL_NUMBER')}: <span>{deviceModel}</span></p>
                        <p>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.SERIAL_NUMBER')}: <span>{deviceSerialNumber}</span></p>
                        {/* <p>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.PURCHASE_DATE')}: <span>{DisplayDate(planEnrolledDate, { year: 'numeric', month: '2-digit', day: '2-digit' })}</span></p> */}
                    </div>
                { CustomerSubscriptionStatus === ACTIVE && <div className="device-details-btn-wrapper">
                        {
                            cancelSwapBtnStatus && !isSwapEligible &&
                            <EcoProButton cname="default white-bg-btn" onBtnClick={() => handleSwapDevice("cancel-swap") }>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.CANCEL_DEVICE_SWAP')}</EcoProButton>
                        }
                        {
                            !cancelSwapBtnStatus && !isSwapEligible &&
                            <EcoProButton cname="default" onBtnClick={() => handleSwapDevice("swap")}>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.SWAP_DEVICE')}</EcoProButton>

                        }
                        {
                            cancelSwapBtnStatus && isSwapEligible &&
                            <EcoProButton cname="default" onBtnClick={() => handleSwapDevice("swap")}>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.SWAP_DEVICE')}</EcoProButton>

                        }
                        {
                            !cancelSwapBtnStatus && isSwapEligible &&
                            <EcoProButton cname="default white-bg-btn" onBtnClick={() => handleSwapDevice("cancel-swap") }>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.CANCEL_DEVICE_SWAP')}</EcoProButton>
                        }
                        {
                            printerType === printerTypes.INKJET?
                            null
                            :
                            <EcoProButton cname="default" onBtnClick={(event) => handleOrderParts(event)}>{t('CUSTOMER_DASHBOARD.OVERVIEW.DEVICE_DETAILS.ORDER_PARTS')}</EcoProButton>
                        }
                    </div> }
                </div>
                <div className="cs-agent order-history-section device-details-wrapper mt-40">
                    <div className="header">
                        <div className="title">
                            <h1>{t("CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.SUPPLIES_ORDER_HISTORY")}</h1>
                        </div>
                    </div>
                    <div className="no-records">{t('No_RECORDS')}</div>
                    { CustomerSubscriptionStatus === ACTIVE && <div className="device-details-btn-wrapper mt-10 mb-60">
                            <EcoProButton cname="default" onBtnClick={(event) => handleOrderSupplies(event)}>{t('CUSTOMER_DASHBOARD.OVERVIEW.SUPPLIES_ORDER_HISTORY.ORDER_SUPPLIES')}</EcoProButton> 
                    </div> }
                </div>
                <EcoProOverlayModal 
                    {...modalProps} 
                    modal_state={modalState}
                    incrementCounter={incrementCounter}
                    decrementCounter={decrementCounter}
                    counter={counter}
                 ></EcoProOverlayModal>
            </div> }
        </div>
    )
}

export default Overview;
