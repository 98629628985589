import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LOCALSESSIONDATAKEYS, READY_PROMOTION, SIGNUPTYPE, refreshBtn, tryAgainBtn } from "../../../../../../constants/AppConstants";
import { setLocalSessionData, WEB_handleSelectedPlanLocalData } from "../../../../../../utils/Authentication/handleUserSession";
import { WebCustomerRoutePaths } from "../../../../../../constants/Routes";
import Loader from "../../../../../../components/shared/loader/Loader";
import WebSubHeader from "../../../../../../components/WebSubHeader/WebSubHeader";
import ListPlan from "../../../../../../components/Web/Customer/ListPlan/ListPlan";
import { FetchServicePlan, GetPromotionBannerStatus } from "../../../../../../actions/Web/Customer/SignUp";
import ErrorPage from "../../../../../../components/shared/errorPage/ErrorPage";
import { pushDataLayer } from "../../../../../../constants/AppMethod";
import check from "../../../../../../assets/images/planFeaturecheck.svg";
import WebSignupProgressBar from "../../../../../../components/WebSubHeader/progressBar/WebSignupProgressBar";
import FAQPlansPage from "../../../../../../components/SelectPlan/FAQ/FAQ";
import LearnMore from "../../../../../../components/SelectPlan/LearnMore/LearnMore";
import PromotionBanner from "../../../../../../components/shared/PromotionBanner/PromotionBanner";
import CaveatText from "../../../../../../components/SelectPlan/CaveatText/CaveatText";


const WebSelectPlan = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const result = useSelector((state) => state.WebServicePlan);
  const bannerResult = useSelector(state => state.GetPromotionBannerStatus)
  const { t } = useTranslation(["translation"]);
  const printerModel = location?.state?.printer_model;
  const history = useHistory();
  const totalSlides = result?.webServicePlan?.length;

  useEffect(() => {
    if(printerModel && printerModel[printerModel.length - 1] === "E"){
      dispatch(FetchServicePlan(printerModel,READY_PROMOTION.promotion));
    }
    else{
      dispatch(FetchServicePlan(printerModel))
    }
    if(!(bannerResult?.success)){
      dispatch(GetPromotionBannerStatus())
    }
  }, [dispatch, printerModel, bannerResult?.success]);

  const moveToAccountPage = (selectedPlan) => {
    pushDataLayer('plan_selected','plan_id',selectedPlan.planId)
    setLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY, selectedPlan.currency);
    WEB_handleSelectedPlanLocalData("Set", selectedPlan);
    history.push({pathname: WebCustomerRoutePaths.WEB_REDIRECT, state:{'signupType': SIGNUPTYPE.WEB}});
  };
  const moveToPreviousPage = () => {
    history.push(WebCustomerRoutePaths.SELECT_PRINTER);
  }
  if(!printerModel){
    return <ErrorPage errorInfo={t('ERROR_CONTENT.ERR_INFO.SELECT_A_DEVICE')} showTryAgain={tryAgainBtn.SHOW} to={WebCustomerRoutePaths.SELECT_PRINTER}></ErrorPage>
  }
  if (result.loading) {
    return <Loader></Loader>;
  }
  if(result.error) {
    return <ErrorPage errorInfo={result.error} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
  }
  if(totalSlides === 0) {
    return <ErrorPage errorInfo={t('ERROR_CONTENT.No_PLANS')} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
  }

 const planFeature = (
  <div className="plan-feature-wrapper">
    <div className="plan-feature">
      <li>
        <img src={check} alt="plan-point" />
        <span>{t("CUSTOMER_WEB.SELECT_PLAN.EXTENDED_WARRANTY")}</span>
      </li>
      <li>
        <img src={check} alt="plan-point" />
        <span>{t("CUSTOMER_WEB.SELECT_PLAN.CANCEL_ANYTIME")}</span>
      </li>
      <li>
        <img src={check} alt="plan-point" />
        <span>{t("CUSTOMER_WEB.SELECT_PLAN.FREE_DELIVERY")}</span>
      </li>
    </div>
  </div>
 )

  return (
    <>
    {bannerResult?.data?.displayPromotionBanner &&
      <PromotionBanner 
        promotionText = {<Trans i18nKey="PROMOTION_BANNER.LIMITED_OFFER">
        Limited-time offer: Exclusive  <strong>6 months Free</strong> EcoPro subscription
    </Trans>}
      ></PromotionBanner>}
      <WebSignupProgressBar activeStep = {1} />
      <WebSubHeader
            currentStep="2"
            totalStep="4"
            stepName={t('SUB_HEADER.STEP_2.STEP_NAME')}
            stepInfo={t('SUB_HEADER.STEP_2.STEP_INFO')}
            moveToPreviousPage={moveToPreviousPage}
            previousBtnText={t('CUSTOMER_WEB.SELECT_PLAN.DEVICE_SELECTION')}
            planFeature={planFeature}
            hideStepInfo={true}
            withProgressBar={true}
      />
      <ListPlan plansData={result} moveToAccountPage={moveToAccountPage}  isPromotionBannerEnabled = {(bannerResult?.data?.displayPromotionBanner) ? true : false} />
      <FAQPlansPage />
      <LearnMore />
      <CaveatText signupType={SIGNUPTYPE.WEB}/>
    </>
  );
};

export default WebSelectPlan;
