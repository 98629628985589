import i18n from 'i18next';
import Backend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LOCALSESSIONDATAKEYS } from '../../constants/AppConstants';
import { allowedWebRoutesToAdaptToBrowserLangSettings, allowedRoutesToAdaptToQueryString } from '../../constants/Routes';
import { setLocalSessionData } from '../Authentication/handleUserSession';

const domain = (new URL(window.location.href));
let detectOptions = {};

if((allowedWebRoutesToAdaptToBrowserLangSettings.indexOf(domain.pathname) > -1)){
  detectOptions = {
    order: ['navigator'],
    caches: ['sessionStorage']
  }
}else if(allowedRoutesToAdaptToQueryString.indexOf(domain.pathname)>-1){
  detectOptions = {
    order:['querystring', 'localStorage'],
    caches: [],
    lookupQuerystring: 'language', 
    lookupLocalStorage: LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE,
  }
} else {
  detectOptions = {
    order: ['localStorage'],
    lookupLocalStorage: LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE,
  }
}

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    detection: detectOptions,
    load: "currentOnly",
    debug: false,
    fallbackLng: 'en-GB',
    ns: ['translation'],
    defaultNS: ['translation'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
        useSuspense: true
    }
});

i18n.on('languageChanged', (lang) => {
  if(allowedWebRoutesToAdaptToBrowserLangSettings.indexOf(domain.pathname) > -1){
    setLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROWEBSIGNUPLANGCODE, lang);
  }
});

export default i18n;