import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Container from "reactstrap/es/Container";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { FetchPrinterModels, FetchPrinterTypes, GetPromotionBannerStatus } from "../../../../../../actions/Web/Customer/SignUp";
import ListPrinterType from "../../../../../../components/Web/Customer/ListPrinterType/ListPrinterType";
import ListPrinterModel from "../../../../../../components/Web/Customer/ListPrinterModel/ListPrinterModel";
import { useQuery } from "../../../../../../utils/CustomHooks/UseQuery";
import { WebCustomerRoutePaths } from "../../../../../../constants/Routes";
import Loader from "../../../../../../components/shared/loader/Loader";
import ErrorPage from "../../../../../../components/shared/errorPage/ErrorPage";
import { refreshBtn } from "../../../../../../constants/AppConstants";
import { pushDataLayer } from "../../../../../../constants/AppMethod";
import WebSubHeader from "../../../../../../components/WebSubHeader/WebSubHeader";
import PromotionBanner from "../../../../../../components/shared/PromotionBanner/PromotionBanner";

const SelectPrinter = (props) => {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const history = useHistory();
    const printerModelsData = useSelector(state => state.PrinterModels);
    const pinterTypesData = useSelector(state => state.PrinterTypes);
    const bannerResult = useSelector(state => state.GetPromotionBannerStatus)
    const [displayModels, setDisplayModels] = useState(false);
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [printerType, setPrinterType] = useState(null);
    const queryParams = useQuery();
    const deviceType= parseInt(queryParams.get("device-type"));
    const deviceModel= queryParams.get("device-model");
    const listPrinterModels = (printerID, printerName) => {
        pushDataLayer('printer_selected','printer_type',printerName)
        dispatch(FetchPrinterModels(printerID));
        setSelectedDeviceType(printerID);
        setPrinterType(printerName);
        setDisplayModels(true);
    }
    useEffect(() => {
        setSelectedDeviceType(deviceType);
        dispatch(GetPromotionBannerStatus())
        if(deviceModel){
            goToPlansPage(deviceModel);
        } else if (deviceType){
            dispatch(FetchPrinterModels(deviceType));
            setDisplayModels(true);
        }
        else{
            dispatch(FetchPrinterTypes())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /**
     * Method to redirect to plans page when printer model and type are provided
     * in the query string
     * @param {string} printerModel
     * return void
     */
    const goToPlansPage = (printerModel) => {
        history.push({pathname: WebCustomerRoutePaths.SELECT_PLAN, state: { printer_model: printerModel }});
      }
    if(pinterTypesData?.loading || bannerResult?.loading){
        return <Loader></Loader>
    }

    if(pinterTypesData?.error) {
        return <ErrorPage errorInfo={pinterTypesData.error} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
    }
    return (
        <>
        {bannerResult?.data?.displayPromotionBanner &&
        <PromotionBanner 
        promotionText = {<Trans i18nKey="PROMOTION_BANNER.LIMITED_OFFER">
        LLimited-time offer: Exclusive  <strong>6 months Free</strong> EcoPro subscription
    </Trans>}        ></PromotionBanner>
        }
        <Container className="mt-40 printer-wrapper variable-width">
            <Helmet>
                <title>Select printer</title>
            </Helmet>
            <WebSubHeader 
                currentStep="1"
                totalStep="4"
                stepName={t('CUSTOMER_WEB.SELECT_PRINTER.SUB_HEADER.STEP_NAME')}
                stepInfo={t('CUSTOMER_WEB.SELECT_PRINTER.SUB_HEADER.STEP_INFO')}
                showBackNavArrow={false}
                hideStepInfo={true}
            />
            <ListPrinterType printerTypes={pinterTypesData?.printerTypes} listPrinterModels={listPrinterModels} selectedType={selectedDeviceType} />
            { displayModels && <ListPrinterModel modelsData={printerModelsData} selectedModel={deviceModel} printerType={printerType}/> }
        </Container>
        </>
    )
};

export default SelectPrinter;