import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import DashboardAccountOverview from "../../../components/DashboardAccountOverview/DashboardAccountOverview";
import DashboardOrdersHistory from "../../../components/DashboardOrdersHistory/DashboardOrdersHistory";
import { AsyncEnrollmentRetry, EnrollmentStatus, FetchOrderHistory, GetUserSubscription, GetUserSubscriptionAccountSummary, ResetUserFeedback } from "../../../actions/Dashboard";
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";
import { getUserSessionData, getLocalSessionData, setLocalSessionData } from "../../../utils/Authentication/handleUserSession";
import "./Accountinfo.css";
import { useHistory } from "react-router-dom";
import { routePath } from "../../../constants/Routes";
import { calculateDaysBetweenDates, convertToYYYYMMDD, getPlanTranslation } from "../../../constants/AppMethod";
import { alertSubscriptionId, DASHBOARD_REFRESH_INTERVAL, ECOPROTOKEN_CLAIMS, FAILURE, LOCALSESSIONDATAKEYS, _API_ENDPOINT } from "../../../constants/AppConstants";
import EcoproAlert from "../../../components/shared/alert/alert";
import { useReducer } from "react";
import { useState } from "react";
import ErrorAlert from "../../../assets/images/error-alert.svg";
import WarningAlert from "../../../assets/images/warning-alert.svg";
import DeviceStatus from "../../../assets/images/dashboard-device-status.svg"
import TrialDaysLeft from "../../../assets/images/dashboard-days-left-trial.svg"
import useIntervalAsync from "./useIntervalAsync";
import { _CALLGETAPI } from "../../../api/apiHandler";
import EnrolledDevice from "./EnrolledDevice";
import Skeleton from "react-loading-skeleton";
import AlertPrinterIcon from  "../../../assets/images/alert-printer-icon.svg";



const AccountInfo = () => {
    const { t } = useTranslation(["translation"]);
    const history = useHistory()
    const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const [userSubscriptionAlertStatus,setUserSubscriptionAlertStatus] = useState(true)
    let planName= 'N/A', printerType = "", planPages= 0,  planPagesLeft= 0, planGivenRolloverPages= 0, planPrintedRolloverPages= 0, planPurchacedOveragePages = 0, planPrintedOveragePages= 0, planPrice = 0, planCurrency = '', discount = [],
    deviceImagePath = "", deviceModel= "",deviceSerialNumber = "",isSwapEligible= "",overageBlockPages= 0,  activatedDate= "", networkStatus="";
    const dispatch = useDispatch();
    const OrdersHistory = useSelector(state => state.DashboardOrderHistory);
    const userSubscriptionAccountSummaryResult = useSelector(state =>state.UserSubscriptionAccountSummary)
    const [deviceInfoError, setDeviceInfoError] = useState("")
    const [deviceInfoLoader, setDeviceInfoLoader] = useState(false)
    const [subscriptionInfo, setsubscriptionInfo] = useState({});
    const [isWithinTermEndDate, setIsWithinTermEndDate] = useState(false)
    const [minimumTermEndDate, setMinimumTermEndDate] = useState(null)
    const [hasFetchedSubscription, setHasFetchedSubscription] = useState(false);
    const enrollmentStatus = useSelector(state => state.EnrollmentStatus);
    const userSubscriptionResult = useSelector(state =>state.GetUserSubscription)
    /**
     * retryAPIEventCount counts how many time retry API is triggered
     * deviceInfoAPIEventCount counts how many times GetDevieInfo is triggred
     */
    let retryAPIEventCount = 0 , deviceInfoAPIEventCount = 0;
    function loginReducer(state, action) {
        switch (action.type) {
          case 'deviceAlert':{
            return {
                ...state,
                [action.alertType] : {...state[action.alertType],displayAlert:action.displayAlert,header:action.header,subHeader:action.subHeader}
            }
          }
          default:
            return state;
        }
      }
    /**
     * Method to check the enrollment/subscription status
     * @param {object} enrollmentData 
     * @returns boolean
     */
    const isSubscriptionEnrolled = useMemo(() => {
        return (enrollmentData = null) => {
          let isValid = false;
          const info = enrollmentData ? enrollmentData : subscriptionInfo;
          const registrationStatus = info?.isEnrollmentAsyncCompleted;
          isValid = registrationStatus;
          return isValid;
        };
      }, [subscriptionInfo]);
    
      useEffect(() => {
        if (!userSubscriptionResult?.success && isSubscriptionEnrolled() && !hasFetchedSubscription) {
          dispatch(GetUserSubscription(UID, deviceID));
          setHasFetchedSubscription(true);
        }
      }, [dispatch, UID, deviceID, userSubscriptionResult, isSubscriptionEnrolled, hasFetchedSubscription]);
    /**
     * Method to check the enrollment/subscription activation status
     * @returns boolean
     */
    const isSubscriptionActivated = () => {
        let isValid = false;
        const subscriptionService = subscriptionInfo?.service;
        const subscriptionActivatedDate = subscriptionService?.activatedDate;
        isValid = (subscriptionService && subscriptionActivatedDate) ? true : false;
        return isValid;
    }
    const updateState = useCallback(async () => {
        const endpoint = _API_ENDPOINT.DEVICE_INFO;
        const payload = {
            params: {
                deviceID: deviceID,
                UserId: UID,
                RequestOrigin: 'Dashboard'
            }
        };
        try {
        if(deviceID && UID){ 
            const response = await _CALLGETAPI(endpoint,payload);
            const data = await response;
            setDeviceInfoLoader(false);
            if(data.actionStatus.toUpperCase() !== FAILURE){
                setsubscriptionInfo(data);
                if(isSubscriptionEnrolled(data)) {
                    dispatch(EnrollmentStatus(true));
                } else {
                    /**
                     * even if retryStatus is true, the retryAPIEventCount limits RetryPlaceOrderAsync to be called only 3 times
                     * The deviceInfoAPIEventCount counts the number of times GetDeviceInfo API is called, only after 2 calls the
                     * RetryPlaceOrderAsync API will be triggered.
                     */
                   //if(data?.ecoProEnrollmentAsyncStatus?.retryStatus === true && retryAPIEventCount < 3  && deviceInfoAPIEventCount > 1) {
                    if(retryAPIEventCount < 3  && deviceInfoAPIEventCount > 1) {

                        dispatch(AsyncEnrollmentRetry(data?.service));
                        // Increment the API call count
                        retryAPIEventCount++;
                   }
                    dispatch(EnrollmentStatus(false));
                }
                deviceInfoAPIEventCount++;
            } else if(data.actionStatus.toUpperCase() !== FAILURE && !data.service){
                setDeviceInfoError(t('ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED'))
            } else{
                setDeviceInfoError(t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR'))
            }
            setDeviceInfoAlert(data)
        }
        else{
            setDeviceInfoLoader(false)
            setDeviceInfoError(t('ERROR_CONTENT.INFO_GENERAL'))
        }
    } catch (e) {
        if(e instanceof TypeError === false && e.message !== "401"){
            setDeviceInfoError(e.message)
          }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    const setDeviceInfoAlert = (data) => {
        const deviceAlert = data?.service?.deviceAlert
        const deviceOfflineFound = deviceAlert?.some(alertType => alertType.descriptionId === alertSubscriptionId.DEVICE_OFFLINE)
        if(deviceOfflineFound){
            reducer_dispatch({
                type: 'deviceAlert',
                alertType: "deviceOffline",
                displayAlert:true,
                header:t('DASHBOARD.ALERT.DEVICE_OFFLINE_HEADER'),
                subHeader:t('DASHBOARD.ALERT.DEVICE_OFFLINE_SUBHEADER',{model:data?.model,serialNumber:data?.serialNumber}),        
            })
        }
        else{
            dismissAlert("deviceOffline")
        }
        // const nonBarracudaCartridgeFound = deviceAlert?.some(alertType => alertType.descriptionId === alertSubscriptionId.NON_BARRACUDA_CARTRIDGE)
        // if(nonBarracudaCartridgeFound){
        //     reducer_dispatch({
        //         type: 'deviceAlert',
        //         alertType: "nonBarracudaCartridge",
        //         displayAlert:true,
        //         header:t('DASHBOARD.ALERT.NON_BARRACUDA_CARTRIDGE_HEADER'),
        //         subHeader:t('DASHBOARD.ALERT.NON_BARRACUDA_CARTRIDGE_SUBHEADER',{model:data?.model,serialNumber:data?.serialNumber}),            
        //     })
        // }
        // else{
        //    dismissAlert("nonBarracudaCartridge")
        // }
        const closeToOverageFound = deviceAlert?.some(alertType => alertType.descriptionId === alertSubscriptionId.CLOSE_TO_OVERAGE)
        if(closeToOverageFound){
            reducer_dispatch({
                type: 'deviceAlert',
                alertType: "closeToOverage",
                displayAlert:true,
                header:t('DASHBOARD.ALERT.CLOSE_TO_OVERAGE_HEADER'),
                subHeader:t('DASHBOARD.ALERT.CLOSE_TO_OVERAGE_SUBHEADER'),
              })
        }
        else{
            dismissAlert("closeToOverage")
         }
        const overageFound = deviceAlert?.some(alertType => alertType.descriptionId === alertSubscriptionId.OVERAGE)
        if(overageFound){
            reducer_dispatch({
                type: 'deviceAlert',
                alertType: "overage",
                displayAlert:true,
                header:t('DASHBOARD.ALERT.OVERAGE_HEADER'),
                subHeader:t('DASHBOARD.ALERT.OVERAGE_SUBHEADER'),
              })
        }
        else{
            dismissAlert("overage")
        }
        const paymentFailedFound = deviceAlert?.some(alertType => alertType.descriptionId === alertSubscriptionId.PAYMENT_FAILED)
        if(paymentFailedFound){
            reducer_dispatch({
                type: 'deviceAlert',
                alertType: "paymentFailed",
                displayAlert:true,
                header:t('DASHBOARD.ALERT.PAYMENT_FAILED_HEADER'),
                subHeader:t('DASHBOARD.ALERT.PAYMENT_FAILED_SUBHEADER'),
              })
        }
        else{
            dismissAlert("paymentFailed")
        }
        if(!isSubscriptionEnrolled(data)){
            reducer_dispatch({
                type: 'deviceAlert',
                alertType: "enrollmentFailed",
                displayAlert:true,
                subHeader:t('DASHBOARD.ALERT.ENROLLMENT_FAILED'),
                alertImg: {AlertPrinterIcon}
              })
        } else{
            dismissAlert("enrollmentFailed")
        }
    }
    /**
     * Method to display an alert if subscription not activated
     * return void
     */
    const displaySubscriptionNotActivatedAlert = () => {
        if(isSubscriptionEnrolled() && !isSubscriptionActivated()){
            reducer_dispatch({
                type: 'deviceAlert',
                alertType: "activatedSubscriptionStatus",
                displayAlert:true,
                subHeader:t('DASHBOARD.ALERT.SUBSCRIPTION_INFO'),        
            })
        }
        else if(activatedDate){
            dismissAlert("activatedSubscriptionStatus")
        }
    }
    /**
     * Method to display an alert containing the trail days after subscription is activated
     * return void
     */
    const displaySubscriptionTrialAlert = () => {
        if(isSubscriptionEnrolled() && isSubscriptionActivated() && discount && discount[0]?.planId && discount[0]?.promotionEndDate){
            const furthestPromotionEndDate =  displayFurthestPromotionEndDate(discount);
            const formattedPromotionEndDate = convertToYYYYMMDD(furthestPromotionEndDate)
            const formattedTodaysDate = convertToYYYYMMDD(new Date())
            const calculateDaysBetweenDatesResult = formattedPromotionEndDate && formattedTodaysDate? calculateDaysBetweenDates(formattedTodaysDate,formattedPromotionEndDate) : null
             if(calculateDaysBetweenDatesResult!==0){
            reducer_dispatch({
                type: 'deviceAlert',
                alertType: "daysLeftForFreeTrial",
                displayAlert:true,
                subHeader:t('DASHBOARD.ALERT.TRIAL_DAYS_LEFT',{daysLeft:calculateDaysBetweenDatesResult}),        
            })
            }
            else{
                dismissAlert("daysLeftForFreeTrial")
            }
        }
        else{
            dismissAlert("daysLeftForFreeTrial")
        }
    }
    useEffect(() => {
        setDeviceInfoLoader(true)
        dispatch(ResetUserFeedback())
        dispatch(FetchOrderHistory(UID));
        dispatch(GetUserSubscriptionAccountSummary(UID))  ;
        dispatch(EnrollmentStatus(false));
    },[dispatch,UID,deviceID]);

    useIntervalAsync(updateState, DASHBOARD_REFRESH_INTERVAL);


    const checkDeviceInfoProps = (key, dataObject) => {
        const defaultVal = (key === "planName") ? 'N/A' : (key === "currency") ? '' : 0;
        return (dataObject.hasOwnProperty(key) && dataObject[key]) ? dataObject[key] : defaultVal;
    }
    const moveToSwapDevice = () => {
        history.push(routePath.CHANGE_DEVICE)
    }
    const displayFurthestPromotionEndDate = (discount) => {
        const maxDate = new Date(
            Math.max(
              ...discount.map(element => {
                return new Date(element?.promotionEndDate);
              }),
            ),
        );
        return maxDate

    }
    if(Object.keys(subscriptionInfo).length > 0 && subscriptionInfo.service !== null){
        activatedDate = checkDeviceInfoProps("activatedDate",subscriptionInfo.service);
        networkStatus = checkDeviceInfoProps("networkStatus", subscriptionInfo)
        if(subscriptionInfo.service && subscriptionInfo.service.hasOwnProperty('currentPlan')
            && subscriptionInfo.service.currentPlan !== null) {
            planName = checkDeviceInfoProps("planName", subscriptionInfo.service.currentPlan);
            printerType = checkDeviceInfoProps("printerType", subscriptionInfo);
            planPrice = checkDeviceInfoProps("overagePrice", subscriptionInfo.service.currentPlan);
            planCurrency = checkDeviceInfoProps("currency", subscriptionInfo.service.currentPlan);
            planPages = checkDeviceInfoProps("planPages", subscriptionInfo.service.currentPlan);
            discount = checkDeviceInfoProps("discount", subscriptionInfo.service.currentPlan);
            overageBlockPages = checkDeviceInfoProps("overageBlockPages", subscriptionInfo.service.currentPlan);
            deviceModel = checkDeviceInfoProps("model", subscriptionInfo)
            deviceSerialNumber = checkDeviceInfoProps("serialNumber", subscriptionInfo)
            deviceImagePath = checkDeviceInfoProps("imagePath",subscriptionInfo)
            isSwapEligible = checkDeviceInfoProps("isSwapEligible",subscriptionInfo)
            setLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY,planCurrency)
        }
        if(subscriptionInfo.service && subscriptionInfo.service.hasOwnProperty('currentUsage') 
            && subscriptionInfo.service.currentUsage !== null) {
            planPagesLeft = checkDeviceInfoProps("printedPlanPages", subscriptionInfo.service.currentUsage);
            planGivenRolloverPages = checkDeviceInfoProps("givenRolloverPages", subscriptionInfo.service.currentUsage);
            planPrintedRolloverPages = checkDeviceInfoProps("printedRolloverPages", subscriptionInfo.service.currentUsage);
            planPurchacedOveragePages = checkDeviceInfoProps("purchasedOveragePages", subscriptionInfo.service.currentUsage);
            planPrintedOveragePages = checkDeviceInfoProps("printedOveragePages", subscriptionInfo.service.currentUsage);
        }
    }
    const alertInitialState = {
        closeToOverage:{
            alertImg:WarningAlert,
            alertClass:"dashboard-warning",
            displayAlert:false,
        },
        overage:{
            alertImg:WarningAlert,
            displayAlert:false,
            alertClass:"dashboard-warning",
        },
        deviceOffline:{
            alertImg:ErrorAlert,
            displayAlert:false,
            alertClass:"dashboard-error",
        },
        // nonBarracudaCartridge:{
        //     alertImg:ErrorAlert,
        //     displayAlert:false,
        //     alertClass:"dashboard-error",
        // },
        paymentFailed:{
            alertImg:ErrorAlert,
            displayAlert:false,
            alertClass:"dashboard-error",
        },
        creditCardCloseToExpiry:{
            alertImg:ErrorAlert,
            displayAlert:false,
            alertClass:"dashboard-error",
        },
        creditCardExpiry:{
            alertImg:ErrorAlert,
            displayAlert:false,
            alertClass:"dashboard-error",
        },
        activatedSubscriptionStatus:{
            alertImg:DeviceStatus,
            displayAlert:false,
            alertClass:"dashboard-subscription-status"
        },
        daysLeftForFreeTrial:{
            alertImg:TrialDaysLeft,
            displayAlert:false,
            alertClass:"dashboard-success"
        },
        enrollmentFailed:{
            alertImg:AlertPrinterIcon,
            displayAlert:false,
            alertClass:"dashboard-error"
        }
    }
    const [state, reducer_dispatch] = useReducer(loginReducer, alertInitialState);

    const dismissAlert = (key) => {
        reducer_dispatch({
            type: 'deviceAlert',
            alertType:key,
            displayAlert:false
          })
    }

    if(userSubscriptionAccountSummaryResult.success && userSubscriptionAlertStatus){
        if(userSubscriptionAccountSummaryResult.success){
            userSubscriptionAccountSummaryResult?.userSubscriptionAccountSummary?.alert?.map(alertType => {
                if(alertType.descriptionId === alertSubscriptionId.CREDIT_CARD_CLOSE_TO_EXPIRY){
                    reducer_dispatch({
                        type: 'deviceAlert',
                        alertType: "creditCardCloseToExpiry",
                        displayAlert:true,
                        header:t('DASHBOARD.ALERT.CLOSE_TO_EXPIRY_DATE_HEADER'),
                        subHeader:t('DASHBOARD.ALERT.CLOSE_TO_EXPIRY_DATE_SUBHEADER'),   
                      })
                }
                if(alertType.descriptionId === alertSubscriptionId.CREDIT_CARD_EXPIRY){
                    reducer_dispatch({
                        type: 'deviceAlert',
                        alertType: "creditCardExpiry",
                        displayAlert:true,
                        header:t('DASHBOARD.ALERT.EXPIRY_DATE_HEADER'),
                        subHeader:t('DASHBOARD.ALERT.EXPIRY_DATE_SUBHEADER'), 
                      })
                }
                return alertType
            })
        }
        setUserSubscriptionAlertStatus(false)
    }

    useEffect(()=>{
        displaySubscriptionNotActivatedAlert();
        displaySubscriptionTrialAlert();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[subscriptionInfo])


    useEffect(() => {
        if(userSubscriptionResult?.success){
            const userSubscriptionViewModel = userSubscriptionResult?.userSubscription?.userSubscriptionViewModels[0]
            if (userSubscriptionViewModel?.deviceOption === "purchase") {
                const today = new Date();
                const endDate = new Date(userSubscriptionViewModel?.termEndDate);
                setIsWithinTermEndDate(today <= endDate)
                if(today <= endDate){
                    setMinimumTermEndDate(userSubscriptionViewModel?.termEndDate)
                    setLocalSessionData(LOCALSESSIONDATAKEYS.TERM_END_DATE, userSubscriptionViewModel?.termEndDate)
                }
                //return today <= endDate;
            } else {
                setIsWithinTermEndDate(false)
            }
        }
    },[userSubscriptionResult])


    if(subscriptionInfo.error) {
        return <ErrorPage errorInfo={subscriptionInfo.error}></ErrorPage>
    }
    if(deviceInfoError){
        return <ErrorPage errorInfo={deviceInfoError}></ErrorPage>
    }

    return (
        <Container className="subscription-info-wrapper">
                {Object.keys(state).map(key => (
                    state[key].displayAlert?
                    <EcoproAlert 
                    alertImg={state[key].alertImg}
                    dismissAlert={()=>dismissAlert(key)}
                    alertHeader={state[key].header}
                    alertSubHeader={state[key].subHeader}
                    alertClass={state[key].alertClass}
                    ></EcoproAlert>
                    :
                    null

                ))
            }

            {!deviceInfoLoader && 
                <div className="plan-name-wrapper mt-40">
                    <div className="f-14 ta-left">{t("DASHBOARD.CURRENT_PLAN")}</div>
                    <div className="f-16 ta-left plan-name">{t('DASHBOARD.CURRENT_PLAN_NAME', { plan_name: getPlanTranslation(planName) , plan_pages: planPages })}</div>
                    {/* {discount && discount[0]?.planId && discount[0]?.promotionEndDate?
                    <div className="f-14 ta-left plan-offer">{t('DASHBOARD.FREE_TRIAL_EXPIRES')} - {displayFurthestPromotionEndDate(discount)}</div>
                    :
                    null} */}
                </div>
            }
            {
                deviceInfoLoader ? 
                (
                    <Skeleton className="para-skeleton1" />
                ) :
                (
                    <EnrolledDevice 
                    deviceImagePath = {subscriptionInfo?.imagePath}
                    deviceModel = {subscriptionInfo?.model}
                    deviceSerialNumber = {subscriptionInfo?.serialNumber}
                    subscriptionDateText = { isWithinTermEndDate ? t('DASHBOARD.ENROLLED_DEVICE_SECTION.MINIMUM_TERM_END_DATE'):t('DASHBOARD.ENROLLED_DEVICE_SECTION.START_DATE')}
                    subscriptionDate = {isWithinTermEndDate ? minimumTermEndDate : activatedDate}
                    deviceStatus= {enrollmentStatus.enrollment_completed}
                    networkStatus={networkStatus}
                    isSwapEligible={isSwapEligible}
                    moveToSwapDevice = {()=>moveToSwapDevice()}
                    />
                )
            }
            <DashboardAccountOverview 
               deviceImagePath = {deviceImagePath}
               deviceModel = {deviceModel}
               deviceSerialNumber = {deviceSerialNumber}
               moveToSwapDevice = {()=>moveToSwapDevice()}
               isSwapEligible = {isSwapEligible}
               planName = {planName}
               printerType = {printerType}
               planPages = {planPages}
               planPagesLeft = {planPagesLeft}
               planRolloverPages ={planGivenRolloverPages}
               planRolloverpagesLeft = {planPrintedRolloverPages}
               planOveragePages = {planPurchacedOveragePages}
               planOveragesPagesLeft = {planPrintedOveragePages}
               planPrice = {planPrice}
               planCurrency = {planCurrency}
               overageBlockPages = {overageBlockPages}
               deviceInfoLoader={deviceInfoLoader}
               cname= {(!enrollmentStatus.enrollment_completed) ? 'disabled-dashboard-blocks' : 'active-dashboard-blocks'}
            ></DashboardAccountOverview>
            <DashboardOrdersHistory 
                orderHistory= {OrdersHistory.orderHistory} 
                isDashboardHomepage={true}
                cname= {(!enrollmentStatus.enrollment_completed) ? 'disabled-dashboard-blocks' : 'active-dashboard-blocks'}
            >
            </DashboardOrdersHistory>
        </Container>
    )
}

export default AccountInfo;