import React, { useEffect } from "react";
import { MsalProvider } from "@azure/msal-react";
import { useLocation } from "react-router";
import { extractLangcodeFromDomain, setAppInternationalization } from "./utils/Internationalization/handleAppInternationalization";
import TagManager from 'react-gtm-module'
import "./App.css";
import { easySetupRoutePath, routePath, routePrefix } from "./constants/Routes";
import { ENVSettings } from "./constants/ENVSettings";
import { getLocalSessionData } from "./utils/Authentication/handleUserSession";
import { LOCALSESSIONDATAKEYS } from "./constants/AppConstants";
import { useQuery } from "./utils/CustomHooks/UseQuery";
import { languageCodes } from "./constants/Country";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import AppRoutes from "./routes/AppRoutes";

const gtmTrackingId = ENVSettings.GTM_TRACKING_ID

const App = ({ msalInstance }) => {
  const { t } = useTranslation(["translation"]);
  const location = useLocation();
  const queryParams = useQuery();
  const language= queryParams.get("language");
  const country= queryParams.get("country");
  const domain = window.location.href;
  const domainLangCode = extractLangcodeFromDomain();
  let languageCode;

  if (domain.includes('?') && language && country){
    languageCode =  `${language}-${domainLangCode.toUpperCase()}`
  } else {
    languageCode = languageCodes[domainLangCode];
  }

  document.documentElement.setAttribute("lang", languageCode);
  
  useEffect(() => {
    window.scrollTo(0,0);
    if (location.pathname === routePath.BENEFIT_OF_SUPPLIES || location.pathname.includes(routePrefix.WEB_CUSTOMER)) {
      document.body.classList.add("teaser-background");
    }
    else{
      document.body.classList.remove("teaser-background");
    }
    if(location.pathname !== routePath.BENEFIT_OF_SUPPLIES || location.pathname !== easySetupRoutePath.BANNER){
      TagManager.initialize({ gtmId: gtmTrackingId });
    }
    const ECOPROLNGCODESTATUS = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROLNGCODESTATUS);
    if(!ECOPROLNGCODESTATUS) {
        setAppInternationalization();
    }
  }, [location.pathname]);

  return (
    <MsalProvider instance={msalInstance}>
      <Helmet>
          <title>{t("PAGE_TITLE.DEFAULT_TITLE")}</title>
          <meta name="description" content={t("PAGE_TITLE.DEFAULT_DESCRIPTION")} />
      </Helmet>
      <div id="backdrop">
          <div className="app_routes">
            <AppRoutes/>
          </div>
      </div>
    </MsalProvider>
  );
};

export default App;
