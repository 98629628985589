import * as actionTypes from '../constants/ActionTypes/MyAccount'
import { PAYMENT_OPTIONS } from '../constants/AppConstants'

export const changeAccountStatus = (accountStatus,formData) => ({
    type: actionTypes.CHANGE_ACCOUNT_STATUS,
    accountStatus,
    formData
})

export const ZuoraPaymentInfoRendering = (paymentType = PAYMENT_OPTIONS.CREDIT_CARD) => ({
    type: actionTypes.ACCOUNT_ZBILLING_INFO_RENDERING_INIT,
    paymentType
})

export const ZuoraPaymentInfoRenderingResetState = () => ({
    type: actionTypes.ZUORA_PAYMENT_INFO_RENDERING_RESET_STATE,
})

export const ManageSubscription = (paymentId, subscriptionPayload, subscriptionType) => ({
    type: actionTypes.ACCOUNT_MANAGE_SUBSCRIPTION_INIT,
    paymentId,
    subscriptionPayload,
    subscriptionType
})
export const ManageSubscriptionResetState = () => ({
    type: actionTypes.MANAGE_SUBSCRIPTION_RESET_STATE
})

export const Enrollment = (placeOrderPayload) => ({
    type: actionTypes.ACCOUNT_ENROLLMENT_INIT,
    placeOrderPayload
})

export const showZipCodeError = (zipErrorStatus) => ({
    type:actionTypes.SHOW_ZIPCODE_ERROR,
    zipErrorStatus
})

export const showMobileError = (field,mobileErrorStatus) => ({
    type:actionTypes.SHOW_MOBILE_ERROR,
    field,
    mobileErrorStatus
})
export const showEmailError = (emailErrorStatus) =>({
    type:actionTypes.SHOW_EMAIL_ERROR,
    emailErrorStatus
})

export const fetchPaymentOptions = () => ({
    type: actionTypes.FETCH_PAYMENT_OPTIONS_INIT
})

export const setPaymentOption = (paymentOption) => ({
    type: actionTypes.SET_PAYMENT_OPTIONS_INIT,
    paymentOption
})

export const UpdateCustomerProfile = (payload) => ({
    type: actionTypes.UPDATE_USER_PROFILE_INIT,
    payload,
})