import React from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import "./PaymentComplete.css"
import { Link } from "react-router-dom";
import { routePath } from "../../constants/Routes";
import currencies from "../../constants/CurrencyTypes";
import completeIcon from "../../assets/images/complete-confirmation-icon.svg";
import { getLocalSessionData } from "../../utils/Authentication/handleUserSession";
import { DEVICE_CARTRIDGE_TYPE, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";
import { formatNumberOnLocale } from "../../constants/AppMethod";

const PaymentComplete = (props) => {
    const {isDiscountAvailed, planPrice, planCurrency, isSubscriptionCompleted = false } = props;
    const { t } = useTranslation(['translation']);
    const cartridgeType = getLocalSessionData(LOCALSESSIONDATAKEYS.CARTRIDGE_TYPE);
    return (
        <Container>
            <div className="acc-complete-img-wrapper">
                <img src={completeIcon} id="signup-completed" alt="signup-completed"></img>
            </div>
            <h1 className="f-20 fc-e">{t('PAYMENT_COMPLETE.PAGE_HEADER')}</h1>
            <div className="acc-subcontent-wrapper mt-10">
                { isDiscountAvailed ? (
                        <>
                            <p className="f-12 fc-g">{t('PAYMENT_COMPLETE.FREE_TRIAL_CONTENT_1')}</p>
                            {
                                cartridgeType === DEVICE_CARTRIDGE_TYPE.HYBRID && <p className="f-12 fc-g">{t('PAYMENT_COMPLETE.HYBRID_CARTRIDGE_TYPE_CONTENT')}</p>
                            }
                            {
                                cartridgeType === DEVICE_CARTRIDGE_TYPE.STANDARD && <p className="f-12 fc-g">{t('PAYMENT_COMPLETE.STANDARD_CARTRIDGE_TYPE_CONTENT')}</p>
                            }
                            <p className="f-12 fc-g trial-price-content"> {t("PAYMENT_COMPLETE.FREE_TRIAL_CONTENT_2", {
                                plan_price: formatNumberOnLocale(planPrice, currencies[planCurrency]?.code),
                            })}</p>
                          </>
                    )
                    : (
                        <>
                            {
                                cartridgeType === DEVICE_CARTRIDGE_TYPE.HYBRID && <p className="f-12 fc-g">{t('PAYMENT_COMPLETE.HYBRID_CARTRIDGE_TYPE_CONTENT')}</p>
                            }
                            {
                                cartridgeType === DEVICE_CARTRIDGE_TYPE.STANDARD && <p className="f-12 fc-g">{t('PAYMENT_COMPLETE.PAGE_CONTENT_2')}</p>
                            }
                            {
                                isSubscriptionCompleted && <p className="f-12 fc-g">{t('PAYMENT_COMPLETE.STANDARD_CARTRIDGE_TYPE_CONTENT')}</p>
                            }
                        </>
                    )
                }
            </div>
            <div className="acc-complete-btn-wrapper">
                <Link className="ecopro_btn default link-txt-align" to={routePath.ENROLLMENT_FEEDBACK} id="enroll_completed">{t("PAYMENT_COMPLETE.BTN_TEXT")}</Link>
            </div>
        </Container>
    );
}

export default PaymentComplete;