import React from "react";
import WebSignupProgressBar from "../../../WebSubHeader/progressBar/WebSignupProgressBar";
import WebSubHeader from "../../../WebSubHeader/WebSubHeader";
import ConnectDeviceImg from "../../../../assets/images/web/connect-device.svg";
import { useTranslation } from "react-i18next";
import EcoProButton from "../../../shared/button/button";
import { getUserDataByKey } from "../../../../utils/Authentication/handleUserSession";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { routePath } from "../../../../constants/Routes";
import { Is_HWInclusive_Signup, pushDataLayer } from "../../../../constants/AppMethod";
import { useDispatch } from "react-redux";
import { ResetAPIsData } from "../../../../actions/Web/Customer/SignUp";
import './ConnectDevice.css'

const ConnectDevice = () => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const displayName = getUserDataByKey("displayName", "personal_details");
  const dispatch = useDispatch();
  const isHardwareInclusiveSignUpType = Is_HWInclusive_Signup();
  const translationKey = (isHardwareInclusiveSignUpType) ? 'CONNECT_DEVICE_HW' : 'CONNECT_DEVICE';

  const moveToAccountPage = () => {
    pushDataLayer("web_signup", "button", 'connect_to');
    dispatch(ResetAPIsData())
    history.push(routePath.CONNECT_DEVICE_DASHBOARD);
  } 

  return (
      <div className="mt-40 payment-page-outer-wrapper">
        <WebSignupProgressBar activeStep={4} completedSteps={[1, 2, 3]} />
        <WebSubHeader
          currentStep="4"
          totalStep="4"
          stepName={t(`CUSTOMER_WEB.${translationKey}.INFO`, {"userName":displayName})}
          stepInfo={(isHardwareInclusiveSignUpType) ? null : t(`CUSTOMER_WEB.CONNECT_DEVICE.GET_STARTED`)}
          stepInfoSub = {t(`CUSTOMER_WEB.CONNECT_DEVICE_HW.FIND_DEVICE`)}
          stepInfoClassName="connect-device-font"
          hideStepInfo={true}
          withProgressBar={true}
          showBackNavArrow={false}
        />

        <div className="connect-device-content">
          <div className="connect-device-img-wrapper">
            <img src={ConnectDeviceImg} alt="connect device" />
          </div>
          {
            (!isHardwareInclusiveSignUpType)
            ? <div className="find-device-txt">{t(`CUSTOMER_WEB.CONNECT_DEVICE.FIND_DEVICE`)}</div>
            : null
          }
          <div className="connect-device-btn">
            <EcoProButton
              cname="web-signup, web-plan-btn f-14"
              btnID={`connect_device`}
              onBtnClick={() => moveToAccountPage()}
            >
              {t(`CUSTOMER_WEB.${translationKey}.CONNECT_DEVICE_STEP`)}
            </EcoProButton>
          </div>
        </div>
      </div>
  );
};

export default ConnectDevice;
