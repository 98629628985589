import { getUserSessionData, getUserDataByKey, getLocalSessionData } from "../Authentication/handleUserSession";
import { getLangCode } from "../Internationalization/handleAppInternationalization";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";
import { truncateAddressLine1Characters } from "../../constants/AppMethod";

const UpdateCustomerProfilePayload = (Subscription) => {
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);;
    const langCode = (getLangCode()) ? getLangCode().split("-")[0] :  "";
    const currency = getLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY);
    const buildCustomerName = () => {
        let displayName = '';
        if(Subscription.accountDetails.personalDetails.firstName){
            displayName = (Subscription.accountDetails.personalDetails.lastName) 
            ?  `${Subscription.accountDetails.personalDetails.firstName} ${Subscription.accountDetails.personalDetails.lastName}` : Subscription.accountDetails.personalDetails.firstName
        } else {
            displayName = (getUserDataByKey('lastName', 'personal_details')) 
            ? `${getUserDataByKey('firstName', 'personal_details')} ${getUserDataByKey('lastName', 'personal_details')}`
            :  getUserDataByKey('firstName', 'personal_details');
        }
        return displayName;
    }
    const customerName = buildCustomerName();
    const billingAddrsDetails = Subscription.accountDetails.billingAddress;
    const truncatedBillingAddressLine1 = truncateAddressLine1Characters(billingAddrsDetails?.address, 0, 15);
    return {
        UserId: UID,
        Name: customerName,
        email: Subscription.accountDetails.personalDetails.emailAddress || "",
        BillingAddress: {
            FirstName: Subscription.accountDetails.personalDetails.firstName,
            LastName: Subscription.accountDetails.personalDetails.lastName,
            Street1: truncatedBillingAddressLine1,
            Street2: billingAddrsDetails.addressLine2 || "",
            City: billingAddrsDetails.city,
            State: billingAddrsDetails.province || "",
            StateName: "",
            PostalCode: billingAddrsDetails.postcode,
            AddressNumber: "",
            country: billingAddrsDetails.country,
            currency:currency,
            companyName: billingAddrsDetails.company || "",
            businessModel: billingAddrsDetails.businessModel || 0,
            vatId: billingAddrsDetails.vatId || "",
            taxId: billingAddrsDetails.taxId || "",
            sapBpId: billingAddrsDetails.sapBpId || "",
            homePhone: Subscription.accountDetails.personalDetails.telephoneNumber || "",
            mobilePhone: Subscription.accountDetails.personalDetails.mobileNumber,
            languageCode: langCode,
            eInvoiceId: billingAddrsDetails.eInvoiceId || ""
        },
        Device: {
            deviceId: deviceID,
        },
    }
}

export default UpdateCustomerProfilePayload;