import React from "react";
import { useTranslation } from "react-i18next";
import { ENVSettings } from "../../../../constants/ENVSettings";
import DisplayUpdateAccountConfirmation from "../../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation";
import checkWithBackground from '../../../../assets/images/complete-confirmation-icon.svg'
import ErrorPage from "../../../../components/shared/errorPage/ErrorPage";
import { getCountryCode } from "../../../../utils/Internationalization/handleAppInternationalization";


const CancellationConfirmed = (props) => {
    const { t } = useTranslation(["translation"]);

    const cancellationConfirmed = (event) => {
      const countryCode = getCountryCode();
      const redirectUrl = (!ENVSettings.MAIN_BASE_URL) ? window.env[`MAIN_BASEURL_${countryCode}`] : ENVSettings.MAIN_BASE_URL;
      event.preventDefault();
      window.location.href = redirectUrl;
      return false;
    }
   
  return (
    props.location.state && props.location.state.cancellationsuccess === true?
    <div>
      <DisplayUpdateAccountConfirmation
        updateAddressHeader={t("CONFIRMED_CANCELLATION.HEADER")}
        updateAddressInfo={t("CONFIRMED_CANCELLATION.INFO")}
        updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CONTINUE")}
        onUpdateClick={(event) => cancellationConfirmed(event)}
        image={checkWithBackground}
      ></DisplayUpdateAccountConfirmation>
    </div>
     :
     <ErrorPage errorInfo={t("ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED")} />
    );
};

export default CancellationConfirmed;
